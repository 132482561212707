import React, { useState, useEffect } from 'react';
import Modal from "react-modal";
import { useTable } from 'react-table';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRobotAstromech  } from '@fortawesome/pro-solid-svg-icons'
import './tables.css';
import { useNavigate } from 'react-router-dom';
import { faScrollOld  } from '@fortawesome/pro-solid-svg-icons'
import { faOctagonXmark  } from '@fortawesome/pro-solid-svg-icons'
import { faComment  } from '@fortawesome/pro-solid-svg-icons'
import { faArrowDownUpAcrossLine } from '@fortawesome/pro-solid-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faHardDrive } from '@fortawesome/pro-solid-svg-icons'
import { faCircle } from '@fortawesome/pro-regular-svg-icons'; // for duotone
import { faCircleQuarterStroke } from '@fortawesome/pro-duotone-svg-icons'; // for duotone 25% circle
import { faCircleHalfStroke } from '@fortawesome/pro-duotone-svg-icons'; // for duotone 50% circle
import { faCircleThreeQuartersStroke } from '@fortawesome/pro-duotone-svg-icons'; // for duotone 75% circle
 
const cellStyle = {
  whiteSpace: 'normal',
  wordWrap: 'break-word',
};

const DataTable = ({ data, columns, expandedRowId, onRowClick }) => {
  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    borderColor: '1px solid #222f4a'
  };

  const thStyle = {
    backgroundColor: '#213454',
    fontWeight: 'bold',
    textAlign: 'left',
    padding: '8px',
    borderBottom: '1px solid #222f4a',
  };

  const tdStyle = {
    padding: '8px',
    borderBottom: '1px solid #2c3038',
    backgroundColor: (row) => (row.original.is_active ? '#8aff80' : 'inherit'),
    borderLeft: '1px solid #222f4a',
    borderRight: '1px solid #222f4a', 
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });



  return (
    <table {...getTableProps()} style={tableStyle}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()} style={thStyle}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      ...tdStyle,
                      backgroundColor: cell.row.original.is_active ? '#8aff80' : 'inherit',
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};





const Subscriptions = () => {
  const navigate = useNavigate();
  const [tableData1, setTableData1] = useState([]); 
  
  

// Correcting the column data structure
const columns1 = React.useMemo(
  () => [
    {
      Header: 'lookup_id',
      accessor: 'lookup_id',
    },
    {
      Header: 'channel',
      accessor: 'channel',
    },
    {
      Header: 'subscribed_ts_pst',
      accessor: 'subscribed_ts_pst',
    },
    {
      Header: 'expires_ts_pst',
      accessor: 'expires_ts_pst',
    },
    {
      Header: 'guid',
      accessor: 'guid',
    },
  ],
  []
);



  useEffect(() => {
    const fetchData = () => {
      // Fetch data for Table 1
      fetch('https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/streams?option=subscription_status')
        .then((response) => response.json())
        .then((jsonData) => {
          //console.log("Fetched Data:", jsonData);
          const parsedData = jsonData.results; // Use jsonData.results directly
          setTableData1(parsedData);
          //console.log("Parsed Data:", parsedData);
        })
        .catch((error) => console.error('Error fetching data for Table 1: ', error));
    };

    fetchData();  // fetch data immediately

    const timer = setInterval(fetchData, 10000); // fetch data every 30 seconds

    return () => clearInterval(timer); // clear interval on component unmount
  }, []); 

return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '1000px' }}>
        <div style={{ marginBottom: '10px', textAlign: 'center' }}>
            <br /><h5>--- subscriptions ---</h5></div>
        <DataTable data={tableData1} columns={columns1} />        
      </div>
    </div>
);

};

export default Subscriptions;