import React, { useState, useEffect, useContext } from 'react';
//import Modal from 'react-modal';
import './channels.css'
import { AuthContext, AuthProvider } from './AuthContext'; 
import './animation.css'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '500px', // Limit the width of the modal
    maxHeight: '80%',  // Limit the height of the modal
    overflow: 'auto',  // Add scroll if content is too long
    backgroundColor: 'rgba(255, 128, 255, 1)'
  }
};

const Channels = () => {
  const { user } = useContext(AuthContext);
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  //const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15); // Set this to how many items you want per page
  const [filterText, setFilterText] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [timeoutId, setTimeoutId] = useState(null);
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/channel-data?username=${user}`)
      .then((response) => response.json())
      .then((jsonData) => {
        setTableData(JSON.parse(jsonData.results));
      })
      .catch((error) => console.error('Error fetching data: ', error));
  }, [])

useEffect(() => {
  return () => {
    clearTimeout(timeoutId);
  };
}, [timeoutId]);

const requestSort = (key) => {
  let direction = 'ascending';
  if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    direction = 'descending';
  } else {
    direction = 'ascending';
  }
  setSortConfig({ key, direction });
};

const getSortedData = () => {
  let sortableItems = [...tableData.filter(item => item.channel_nickname.toLowerCase().includes(filterText.toLowerCase()))];
  if (sortConfig !== null) {
    sortableItems.sort((a, b) => {
      if (typeof a[sortConfig.key] === 'string') {
        return sortConfig.direction === 'ascending' 
          ? a[sortConfig.key].localeCompare(b[sortConfig.key], 'en', { sensitivity: 'base' }) 
          : b[sortConfig.key].localeCompare(a[sortConfig.key], 'en', { sensitivity: 'base' });
      } else {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
      }
      return 0;
    });
  }
  return sortableItems;
};


const sortedData = getSortedData();

const filteredResults = tableData.filter(item => 
  item.channel_nickname.toLowerCase().includes(filterText.toLowerCase()) ||
  item.title.toLowerCase().includes(filterText.toLowerCase()) ||
  item.channel_lookup_id.toString().includes(filterText)
);

  const totalPagesFiltered = Math.ceil(filteredResults.length / itemsPerPage);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentResults = filteredResults.slice(firstIndex, lastIndex);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);

  };


  const handleRowMouseEnter = (channel) => {
    setSelectedRow(channel);
  };

return (
  <div className="container"> 
    <div className="left-pane"> 
       <div className="data">
               <input
          type="text"
          value={filterText}
          onChange={handleFilterChange}
          className="filter-input"
          placeholder="filter"
        />
        </div>
      <div className="search-results-container">


        <table className="data">

          <thead>
            <tr>
              <th onClick={() => requestSort('channel_lookup_id')}>id</th>
              <th onClick={() => requestSort('channel_nickname')}>nickname</th>
              <th onClick={() => requestSort('title')}>title</th>
              <th onClick={() => requestSort('subs')}>subs</th>
              <th onClick={() => requestSort('views')}>views</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((channel) => (
              <tr
                key={channel.channel_lookup_id}
                className={`tableRow ${selectedRow && selectedRow.channel_lookup_id === channel.channel_lookup_id ? 'selected' : ''}`}
                onMouseEnter={() => handleRowMouseEnter(channel)}
              >
                <td className={`tableCell ${selectedRow && selectedRow.channel_lookup_id === channel.channel_lookup_id ? 'selected-text' : ''}`}>
                  {channel.channel_lookup_id}
                </td>
                <td className={`tableCell ${selectedRow && selectedRow.channel_lookup_id === channel.channel_lookup_id ? 'selected-text' : ''}`}>
                  {channel.channel_nickname}
                </td>
                <td className={`tableCell ${selectedRow && selectedRow.channel_lookup_id === channel.channel_lookup_id ? 'selected-text' : ''}`}>
                  {channel.title}
                </td>
                <td className={`tableCell ${selectedRow && selectedRow.channel_lookup_id === channel.channel_lookup_id ? 'selected-text' : ''}`}>
                  {channel.subs}
                </td>
                <td className={`tableCell ${selectedRow && selectedRow.channel_lookup_id === channel.channel_lookup_id ? 'selected-text' : ''}`}>
                  {channel.views}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
     
      </div>
     
    </div>
        <div className="right-pane" style={{ backgroundImage: `url(${selectedRow?.thumbnail_default})` }}>
      {/* Right pane content - make sure this container can scroll */}

      <div className="scrollable-content">
        {selectedRow && (
          <>
            <p></p>
            <p></p>
            <p></p>
            <h3 className="selected-text">{selectedRow.title}</h3>
            <p className="selected-text"> [ {selectedRow.channel_nickname} ] [ {selectedRow.channel_lookup_id} ]</p>
            <p>stats:</p>
            <p>-- subscribers: {selectedRow.subs} views: {selectedRow.views}</p>
            <p>videos:</p>
            <p>-- youtube: {selectedRow.videos_yt} local: {selectedRow.combined_video_count} archived: {selectedRow.combined_video_archived} transcribed: {selectedRow.combined_video_transcribed}</p>
            <p>-- videos: {selectedRow.combined_video_count - selectedRow.streams} archived: {selectedRow.videos_archived} transcribed: {selectedRow.videos_transcribed}</p>
            <p>-- last published: {selectedRow.last_video_published_ts}</p>
            <p>streams:</p>
            <p>-- streams: {selectedRow.streams} archived: {selectedRow.streams_archived} Transcribed: {selectedRow.streams_transcribed}</p>
            <p>-- last streamed: {selectedRow.last_stream_completed_ts}</p>
            <p>transcribed hours: {selectedRow.transcribed_hours} terabytes on disk: {selectedRow.archived_tb_util}</p>
            <p>chat data:</p>
            <p>-- chat entries: {selectedRow.chat_count} superchats: {selectedRow.superchat_count}</p>
            <p>-- superchat_revenue: ${selectedRow.superchat_sum_usd}</p>
            <p>-- 12 | 6 | 3 | 1 month superchats: ${selectedRow.superchat_sum_usd_prev_12_month} | ${selectedRow.superchat_sum_usd_prev_6_month} | ${selectedRow.superchat_sum_usd_prev_3_month} | ${selectedRow.superchat_sum_usd_prev_1_month}</p>
            <p>status:</p>
            <p>-- has Memberships: {String(selectedRow.has_memberships)}</p>
            <p>-- is Live Monitored: {String(selectedRow.is_live_monitored)}</p>
            <p>-- is Summarization Enabled: {String(selectedRow.is_summarization_enabled)}</p>
          </>
        )}

      </div>
    </div>
  </div>
);
};

export default Channels;
