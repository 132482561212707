import React, { useState, useEffect, useContext } from 'react';
import Modal from "react-modal";
import { useTable } from 'react-table';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRobotAstromech  } from '@fortawesome/pro-solid-svg-icons'
//import './tables.css';
import './archivals.css';
import { useNavigate } from 'react-router-dom';
import { faScrollOld  } from '@fortawesome/pro-solid-svg-icons'
import { faOctagonXmark  } from '@fortawesome/pro-solid-svg-icons'
import { faComment  } from '@fortawesome/pro-solid-svg-icons'
import { faDownload  } from '@fortawesome/pro-solid-svg-icons'
import { faArrowDownUpAcrossLine } from '@fortawesome/pro-solid-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faHardDrive } from '@fortawesome/pro-solid-svg-icons'
import { faCircle } from '@fortawesome/pro-regular-svg-icons'; // for duotone
import { faCircleQuarterStroke } from '@fortawesome/pro-duotone-svg-icons'; // for duotone 25% circle
import { faCircleHalfStroke } from '@fortawesome/pro-duotone-svg-icons'; // for duotone 50% circle
import { faCircleThreeQuartersStroke } from '@fortawesome/pro-duotone-svg-icons'; // for duotone 75% circle
import { AuthContext, AuthProvider } from './AuthContext'; 

const cellStyle = {
  whiteSpace: 'normal',
  wordWrap: 'break-word',
};

const DataTable = ({ data, columns, expandedRowId, onRowClick }) => {
  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    borderColor: '1px solid #222f4a'
  };

  const thStyle = {
    backgroundColor: '#213454',
    fontWeight: 'bold',
    textAlign: 'left',
    padding: '8px',
    borderBottom: '1px solid #222f4a',
  };

  const tdStyle = {
    padding: '8px',
    borderBottom: '1px solid #2c3038',
    backgroundColor: (row) => (row.original.is_active ? '#8aff80' : 'inherit'),
    borderLeft: '1px solid #222f4a',
    borderRight: '1px solid #222f4a', 
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

if (!Array.isArray(data)) {
    return <div>Error: Data is not in the correct format</div>;
}


  return (
    <table {...getTableProps()} style={tableStyle}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()} style={thStyle}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      ...tdStyle,
                      backgroundColor: cell.row.original.is_active ? '#8aff80' : 'inherit',
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};


const Archivals = () => {
  const navigate = useNavigate();
  const [tableData1, setTableData1] = useState([]); 
  //console.log("Rendering Archivals");
  const { user } = useContext(AuthContext);

  const [archivalData, setArchivalData] = useState([]);
  

// Correcting the column data structure
const columns1 = React.useMemo(
  () => [
    {
      Header: 'channel',
      accessor: 'channel_nickname',
    },
    {
      Header: 'video_id',
      accessor: 'video_id',
      width: 120, // or any fixed width
      maxWidth: 120, // maximum width
      Cell: ({ value, row }) => (
        <div style={{
          whiteSpace: 'wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '120px', // ensure consistency with maxWidth above
        }}>
          <a href={`https://www.youtube.com/watch?v=${row.original.video_id}`} target="_blank" rel="noopener noreferrer">
            {value}
          </a>
        </div>
      ),
    },
    {
      Header: 'published_ts_utc',
      accessor: 'video_published_ts_utc',
    },
      {
        Header: '',
        accessor: 'video_thumbnail',
        Cell: ({ value, row }) => (
          <img src={value} alt="Thumbnail" style={{ width: '100px' }} /> // Set the src attribute for image URL
        ),
      },
{
  Header: '',
  accessor: 'video_title',
  width: 190, // or any fixed width
  maxWidth: 190, // maximum width
  Cell: ({ value, row }) => (
    <div style={{
      whiteSpace: 'wrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '190px', // ensure consistency with maxWidth above
    }}>
      <a href={`https://www.youtube.com/watch?v=${row.original.video_id}`} target="_blank" rel="noopener noreferrer">
        {value}
      </a>
    </div>
  ),
},
    {
      Header: 'requestor',
      accessor: 'requested_username',
    },
    {
      Header: 'requested_utc',
      accessor: 'requested_ts_utc',
    },
    {
      Header: 'available',
      accessor: 'is_uploaded_bool',
      Cell: ({ value }) => String(value), // Convert boolean to string
    },
{
  Header: 'link',
  accessor: 's3_presigned_url',
  Cell: ({ value }) => {
    return value ? (
      <a href={value} download>
        <FontAwesomeIcon icon={faDownload} /> {/* Replace with your download icon */}
      </a>
    ) : null;
  },
},
    {
      Header: 'link_expires_utc',
      accessor: 's3_presigned_url_expiration_ts_utc',
    },    
    {
      Header: 'is_upload_pending_bool',
      accessor: 'is_upload_pending_bool',
      Cell: ({ value }) => String(value), // Convert boolean to string
    },
    {
      Header: 'upload_began_ts_utc',
      accessor: 'upload_begin_ts_utc',
    },    
    {
      Header: 'upload_complete_ts_utc',
      accessor: 'upload_complete_ts_utc',
    },    
    {
      Header: 'status',
      accessor: 'request_status',
    },    
    {
      Header: 'note',
      accessor: 'request_note',
    },    
    {
      Header: 'link remaining time',
      accessor: 's3_presigned_url_remaining_time',
    },    
  ],
  []
);

useEffect(() => {
  const fetchData = () => {
    fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/get-archival-status?username=${user}`)
      .then((response) => response.json())
      .then((jsonData) => {
        if (Array.isArray(jsonData.results) && jsonData.results.length > 0) {
          // Data is not empty, set it to the state
          const parsedData = jsonData.results;
          setTableData1(parsedData);
          setArchivalData(jsonData.results);
        } else {
          // Data is empty, handle it accordingly (e.g., show a message)
          console.log('No data available');
        }
      })
      .catch((error) => console.error('Error fetching data for Table 1: ', error));
  };

  fetchData(); // Fetch data immediately

    const timer = setInterval(fetchData, 10000); // fetch data every 30 seconds

    return () => clearInterval(timer); 
  }, []); 

//console.log(`archivals logged in user is: ${user}`);

  return (
    <div className="archivals-container">
      {archivalData.map((item, index) => (
        <div key={index} className="archival-card">
        <div className="thumbnail-container">
          <img src={item.video_thumbnail} alt="Thumbnail" />
            {item.s3_presigned_url && (
              <a 
                href={item.s3_presigned_url} 
                className="download-overlay" // Apply the new class here
                download
              >
              <FontAwesomeIcon 
                size="4x" 
                icon={faDownload} 
                className="download-icon" // Apply the class for icon color
              />
              </a>
            )}
        </div>
          <div className="details-container">
            <h5>{item.video_title}</h5>
            <p><strong>channel:</strong> {item.channel_nickname}&nbsp;&nbsp;&nbsp;<strong>video_id:</strong> {item.video_id}</p>
            <p><strong>requestor:</strong> {item.requested_username}</p>
            <p><strong>request note:</strong> {item.request_note}</p>
            <p className="green-text">{item.s3_presigned_url && ( 
              <p><strong>link expiration: </strong>{item.s3_presigned_url_remaining_time}</p>
              )
            }</p>
            {!item.s3_presigned_url && ( 
              <p><strong>requested time: </strong>{item.requested_ts_utc} UTC</p>
              )
            }
{item.request_status === 'processing' && ( 
  <p className="orange-text">
    <span className="blinking-text">request is being processed.. please wait a few minutes</span>
  </p>
)}
{item.request_status === 'requested' && ( 
  <p className="orange-text">
    <span className="blinking-text">request pending - keep checking</span>
  </p>
)}
{item.request_status === 'expired' && ( 
  <p className="orange-text">
    <span>link expired. create new request if needed</span>
  </p>
)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Archivals;