import React, { useState, useEffect } from 'react';
import './tables.css'; // Import the CSS file

const TranscriptSearch = ({ onSearch }) => {
  const today = new Date();
  const sevenDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2); // getMonth() is zero-indexed, so we must increment it
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  }

  const [selectedChannel, setSelectedChannel] = useState('all');
  const [startDate, setStartDate] = useState(formatDate(sevenDaysAgo));
  const [endDate, setEndDate] = useState(formatDate(today));
  const [activeButton, setActiveButton] = useState('30-day');

  const [inputText, setInputText] = useState('');
  const [inputText2, setInputText2] = useState('');

  // We are creating a new state to track whether to run a search
  const [runSearch, setRunSearch] = useState(false);
//
  useEffect(() => {
    if (runSearch) {
      handleSearchClick();
      setRunSearch(false);
    }
  }, [runSearch]);

  const handleYesterdayClick = () => {
    const today = new Date();
    const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    setStartDate(formatDate(yesterday));
    setEndDate(formatDate(today));
    setActiveButton('24-hour');
    setRunSearch(true);
  };

  const handleSevenDaysClick = () => {
    const today = new Date();
    const sevenDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    setStartDate(formatDate(sevenDaysAgo));
    setEndDate(formatDate(today));
    setActiveButton('7-day');
    setRunSearch(true);
  };

  const handleThirtyDaysClick =  () => {
    const today = new Date();
    const thirtyDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);
    setStartDate(formatDate(thirtyDaysAgo));
    setEndDate(formatDate(today));
    setActiveButton('30-day');
  };

  const handleOneYearClick = () => {
    const today = new Date();
    const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
    setStartDate(formatDate(oneYearAgo));
    setEndDate(formatDate(today));
    setActiveButton('365-day');
  };

  const handleAllTimeClick = () => {
    const today = new Date();
    const allTimeAgo = new Date(today.getFullYear() - 10, today.getMonth(), today.getDate());
    setStartDate(formatDate(allTimeAgo));
    setEndDate(formatDate(today));
    setActiveButton('all-time');
  };


// new code

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleInputChange2 = (e) => {
    setInputText2(e.target.value);
  };

  const handleSearchClick = () => {
    const searchParams = {
      searchText: inputText,
      searchText2: inputText2,
      channel_nickname: selectedChannel,
      startDate,
      endDate
    };
    onSearch(searchParams);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  };
  
  return (
    
<div className="transcript-search-outer-container">
<div className="transcript-search-container">
      
<div className="date-buttons">
  <button className={activeButton === '24-hour' ? 'active' : ''}
    onClick={handleYesterdayClick}>24-hour</button>
  <button className={activeButton === '7-day' ? 'active' : ''}
    onClick={handleSevenDaysClick}>7-day</button>
  <button className={activeButton === '30-day' ? 'active' : ''}
    onClick={handleThirtyDaysClick}>30-day</button>
  <button className={activeButton === '365-day' ? 'active' : ''}
    onClick={handleOneYearClick}>365-day</button>
  <button className={activeButton === 'all-time' ? 'active' : ''}
    onClick={handleAllTimeClick}>all-time</button>
</div>
      
      <input
        type="text"
        value={inputText}
        onChange={handleInputChange}
        className="search-input"
        placeholder="search query"
        onKeyPress={handleKeyPress}
      />
      <input
        type="text"
        value={inputText2}
        onChange={handleInputChange2}
        className="search-input"
        placeholder="AND query"
        onKeyPress={handleKeyPress}
      />
      <select
        value={selectedChannel}
        onChange={(e) => setSelectedChannel(e.target.value)}
        className="channel-select"
      >
        <option disabled>---all channels---</option>
        <option value="all">search-all-channels</option>
        <option disabled>---archie---</option>
        <option value="ac3">archie-livestreams</option>
        <option value="archieluxury">archie-luxury</option>
        <option value="paulplutaprestige">paul-pluta-prestige</option>
        <option value="dungeon">dungeon</option>
        <option disabled>---watch-nicholas---</option>
        <option value="wn">watch-nicholas</option>
        <option value="wn_live">watch-nicholas-live</option>
        <option disabled>---timmy---</option>
        <option value="timmy">tim-write</option>
        <option value="community">tim-write-community</option>
        <option value="ocean">oisin</option>
        <option value="ibashers">ibashers</option>
        <option disabled>---archieverse---</option>
        <option value="mookie">mookie</option>
        <option value="villages">the-villages</option>
        <option value="kushclub">kush-club</option>
        <option value="crappy">crappy-luxury</option>
        <option value="colony">colony</option>
        <option value="diego">diego</option>
        <option value="extreme">extreme-horology</option>
        <option value="doxx">doxx-reports</option>
        <option value="greenstream">greenroom</option>
        <option value="jj">jj</option>
        <option value="hhangout">hhangout</option>
        <option value="luke">luke-justen</option>
        <option value="luxx">luxx</option>
        <option value="marcelo">marcelo</option>
        <option value="austin">austin-daniels</option>
        <option value="LoudMouthTim">loud-mouth-tim</option>
        <option value="angelic">angelic</option>
        <option value="dis">dis</option>
        <option value="reporter">watch-reporter</option>
        <option value="elite">elite-horology</option>
        <option disabled>---random---</option>
        <option value="davidconnelly">david-connelly</option>
        <option value="jimmyc">jimmy-c-and-friends</option>
        <option value="allin">allin</option>
        <option disabled>---gray-marketeers---</option>
        <option value="tpg">tpg</option>
        <option value="timepiecevideos">timepiece-videos</option>
        <option value="gc">grand-caliber</option>
        <option value="paulthorpe">paul-thorpe</option>
      </select>
      <label htmlFor="start-date"></label>
      <input
        type="date"
        id="start-date"
        value={startDate}
        onChange={(e) => {setStartDate(e.target.value); setActiveButton(null);}}
/>
      <label htmlFor="end-date"></label>
      <input
        type="date"
        id="end-date"
        value={endDate}
        onChange={(e) => {setEndDate(e.target.value); setActiveButton(null);}}
/>
      
      <button onClick={handleSearchClick}>search</button>
      
    </div>
    </div>
  );
};



export default TranscriptSearch;
