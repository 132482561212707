import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTable } from 'react-table';
import { AreaChart, Area, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';
import { format, parseISO, startOfDay, subDays } from 'date-fns';

const cellStyle = {
  whiteSpace: 'normal',
  wordWrap: 'break-word',
};

const formatXAxis = (tickItem) => {
  return format(parseISO(tickItem), 'yyyy-MM-dd');
};

const prepareChartData = (data) => {
  let groupedData = {};
  let channels = new Set();
  
  // Generate dates for the last 180 days
  let last180Days = Array.from({length: 180}, (_, i) => startOfDay(subDays(new Date(), i)).toISOString()).reverse();

  data.forEach(item => {
    const date = startOfDay(parseISO(item.chat_timestamp)).toISOString();
    channels.add(item.channel);
    if (!groupedData[item.channel]) {
      groupedData[item.channel] = {};
    }
    if (!groupedData[item.channel][date]) {
      groupedData[item.channel][date] = 0;
    }
    groupedData[item.channel][date] += item.superchat_usd;
  });

  channels = Array.from(channels);
 

  // Map over last180Days instead of dates from data
  const chartData = last180Days.map(date => {
    let obj = { date };
    for (let channel in groupedData) {
      if (groupedData[channel][date]) {
        obj[channel] = groupedData[channel][date];
      } else {
        obj[channel] = 0;
      }
    }
    return obj;
  });

  return { chartData, channels };
};

const DonorSummary = () => {
  let { donorId } = useParams();
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [channels, setChannels] = useState([]);
  const [dateTicks, setDateTicks] = useState([]); // Define the dateTicks state variable

  useEffect(() => {
    fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/superchats?option=donor_12_month&donor_id=${donorId}`)
      .then((response) => response.json())
      .then((jsonData) => {
        const parsedData = JSON.parse(jsonData.results);
        const { chartData, channels } = prepareChartData(parsedData);
        setData(parsedData);
        setChartData(chartData);
        setChannels(channels);
        
        // Calculate dateTicks after setting chartData
        const oneWeek = 1000 * 60 * 60 * 24 * 7; // number of milliseconds in one week
        const minDate = new Date(chartData[0].date);
        const maxDate = new Date(chartData[chartData.length - 1].date);
        const tempDateTicks = [];
        for (let d = minDate; d <= maxDate; d = new Date(d.getTime() + oneWeek)) {
          tempDateTicks.push(d.toISOString());
        }
        //setDateTicks(tempDateTicks);
      })
      .catch((error) => console.error('Error fetching data: ', error));

  }, [donorId]);


  const channelColors = {
  wn: '#052afc',
  ac3: '#309906',
  dungeon: '#145240',
  dis: '#008585',
  jj: '#547a78',
  timmy: '#ba1493',
  community: '#8608a8',
  ocean: '#780611'
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'timestamp',
        accessor: 'chat_timestamp',
        Cell: ({ value }) => format(new Date(value), 'yyyy-MM-dd HH:mm'),
        width: '50px',
      },
      {
        Header: 'channel',
        accessor: 'channel',
        width: '25px',
      },
      {
        Header: 'video_id',
        accessor: 'video_id',
        width: '50px'
      },
      {
        Header: 'donor_name',
        accessor: 'username',
        width: '50px',
      },
      {
        Header: 'value_usd',
        accessor: 'superchat_usd',
        width: '25px',
      },
      {
        Header: 'message',
        accessor: 'message',
        Cell: ({ value }) => <div style={cellStyle}>{value}</div>
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <div>
      
<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
  <AreaChart
    width={0.75 * window.innerWidth}
    height={600} // Increase the height
    data={chartData}
    margin={{ top: 5, right: 20, left: 10, bottom: 50 }} // Increase the bottom margin
  >
    <XAxis dataKey="date" 
           tickFormatter={formatXAxis} 
           angle={-45} 
           textAnchor="end" 
           ticks={dateTicks} 
           height={100} />
    <YAxis />
<Tooltip 
  content={({ payload, label, active }) => {
    if (active && payload) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#f5f5f5', padding: '5px', border: '1px solid #cccccc' }}>
          <p className="label">{`Date : ${format(parseISO(label), 'yyyy-MM-dd')}`}</p>
          {payload.map((pl, index) => (
            <p key={index} className="desc" style={{ color: pl.color }}>
              {`${pl.name} : ${pl.value.toFixed(2)}`} {/* Here the value is being rounded to two decimal places */}
            </p>
          ))}
        </div>
      );
    }
    return null;
  }}
/>
    <Legend />
    <CartesianGrid stroke="#f5f5f5" />

    {channels.map(channel => {
      
      return (
        <Area
          key={channel} 
          type="monotone" 
          dataKey={channel} 
          stroke={channelColors[channel] || '#000000'} // use colors from the channelColors object, default to black if not found
          fill={channelColors[channel] || '#000000'} // fill area with the same color as the stroke, default to black if not found
          stackId="1"
          yAxisId={0} 
          dot={false} 
        />
      );
    })}

  </AreaChart>
</div>
      
      <br /><br />
      <h5 style={{textAlign: 'center'}}>Last 100 superchats</h5>
      <br />
      <table {...getTableProps()} style={{tableLayout: 'fixed', width: '100%'}}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} style={{...cellStyle, width: column.width}}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} style={{...cellStyle, width: cell.column.width}}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DonorSummary;



