import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React, { useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Navbar, Nav, Button } from 'react-bootstrap';

import './CustomNavbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTelescope } from '@fortawesome/pro-thin-svg-icons'
import { faRobotAstromech  } from '@fortawesome/pro-solid-svg-icons'
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons'

import { AuthContext } from './AuthContext';

const CustomNavbar = ({ isIframe }) => {
  
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  if (isIframe) {
    return null; // Don't render the navbar if in an iframe
  }

return (
<div>
  <div className="logo-container">
  </div>
  <Navbar bg="dark" variant="dark" expand="lg">
    <FontAwesomeIcon icon={faRobotAstromech} className="fa-thin" style={{ color: 'blue', display: 'inline-block', marginLeft: '25px', marginRight: '15px' }} /> 
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link as={Link} to="/">home</Nav.Link>
        <Nav.Link as={Link} to="/transcripts"><FontAwesomeIcon icon={faMagnifyingGlass} className="fa-thin" style={{ paddingTop: '0px', color: 'blue', display: 'inline-block', marginLeft: '5px', marginRight: '5px' }} />search</Nav.Link>
        <Nav.Link as={Link} to="/searchV2">search-v2</Nav.Link>
        <Nav.Link as={Link} to="/searchV3">search-v3</Nav.Link>
        <Nav.Link as={Link} to="/streams2">streams</Nav.Link>
        <Nav.Link as={Link} to="/Superchat">revenue</Nav.Link>
        <Nav.Link as={Link} to="/SuperchatCompare">analyze</Nav.Link>
        <Nav.Link as={Link} to="/users">users</Nav.Link>
        <Nav.Link as={Link} to="/channels">channels</Nav.Link>
        <Nav.Link as={Link} to="/archivals">archivals</Nav.Link>
        <Nav.Link as={Link} to="/subscriptions">subscriptions</Nav.Link>
        <Nav.Link as={Link} to="/mentions">mentions</Nav.Link>
        <Nav.Link as={Link} to="/videos">videos</Nav.Link>
        
      </Nav>
      <div className="ml-auto d-flex align-items-center">
        <div style={{ width: '100%' }}>
          <Button variant="outline-light" onClick={handleLogout}>logout</Button> {/* Add the logout button */}
        </div>
      </div>
    </Navbar.Collapse>
  </Navbar>
</div>
);


};

export default CustomNavbar;



