import React, { useState, useEffect, useContext } from 'react'; // Add useContext here
import ReactDOM from 'react-dom';
import './tables.css'; // Import the CSS file
//import YouTube from 'react-youtube';
import ReactPlayer from 'react-player';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faOctagonXmark  } from '@fortawesome/pro-solid-svg-icons'
import { faUpload  } from '@fortawesome/pro-solid-svg-icons'
import { faChevronRight  } from '@fortawesome/pro-solid-svg-icons'
import { faChevronsRight  } from '@fortawesome/pro-solid-svg-icons'
import { faChevronLeft  } from '@fortawesome/pro-solid-svg-icons'
import { faChevronsLeft  } from '@fortawesome/pro-solid-svg-icons'
import { faArrowLeftToLine  } from '@fortawesome/pro-solid-svg-icons'
import { faArrowRightToLine  } from '@fortawesome/pro-solid-svg-icons'
import { faScissors } from '@fortawesome/pro-duotone-svg-icons'
import { faScrollOld  } from '@fortawesome/pro-solid-svg-icons'
import { faDownload  } from '@fortawesome/pro-solid-svg-icons'
import { AuthContext, AuthProvider } from './AuthContext'; 
import ReactGA from "react-ga4";

const SearchResults = ({ searchText, searchText2, channel_nickname, startDate, endDate }) => {
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(8);
  const [selectedRow, setSelectedRow] = useState(null); // Track the selected row
  const totalPages = Math.ceil(results.length / resultsPerPage);
  const totalPagesFiltered = Math.ceil(filteredResults.length / resultsPerPage);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [clipModalOpen, setClipModalOpen] = useState(false);
  const [ArchivalModalOpen, setArchivalModalOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  const [startTimeOffset, setStartTimeOffset] = useState(0);
  const defaultRequestNote = '';
  const [requestNote, setRequestNote] = useState(defaultRequestNote);
  const [clipDuration, setClipDuration] = useState(30);
  const [selectedResult, setSelectedResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Add this state
  const { user } = useContext(AuthContext);
  const [allDistinctChannelNicknames, setAllDistinctChannelNicknames] = useState([]);
  const [initialSelectedChannelNicknames, setInitialSelectedChannelNicknames] = useState(new Set()); 
  const [selectedChannelNicknames, setSelectedChannelNicknames] = useState(initialSelectedChannelNicknames);
  const element = <FontAwesomeIcon icon={faOctagonXmark} size="lg" />

  const [cameFromClipModal, setCameFromClipModal] = useState(false);
  const [showYoutubeUploadModal, setShowYoutubeUploadModal] = useState(false);
  const [youtubeUploadData, setYoutubeUploadData] = useState({
    video_title: '',
    video_description: '',
    video_type: 'regular',
    schedule_time: 'now',
    hashtags: '',
    channel: 'dungeon'
  });


  const handleYoutubeUploadClick = (event) => {
    event.stopPropagation();
    setCameFromClipModal(true);
    setShowYoutubeUploadModal(true);
  };

  const closeYoutubeUploadModal = () => {
    setShowYoutubeUploadModal(false);
    if (cameFromClipModal) {
      setShowClipModal(true);
      setCameFromClipModal(false);
    }
  };
  const handleYoutubeUploadInputChange = (e) => {
    setYoutubeUploadData({
      ...youtubeUploadData,
      [e.target.name]: e.target.value
    });
  };

const uploadToYoutube = (formData) => {
  const { video_title, video_description, video_type, schedule_time, hashtags, channel } = formData;
  const s3url = encodeURIComponent(clipUrl);
  const encodedTitle = encodeURIComponent(video_title);
  const encodedDescription = encodeURIComponent(video_description);
  const encodedHashtags = encodeURIComponent(hashtags);
  //console.log('encodedHashtags:',encodedHashtags);
  const apiUrl = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/publish_to_youtube?s3url=${s3url}&channel=${channel}&title=${encodedTitle}&description=${encodedDescription}&start_time=${schedule_time}&video_type=${video_type}&hashtags=${encodedHashtags}`;

  //console.log('formData inside uploadToYoutube:',formData)
  fetch(apiUrl)
    .then(response => response.json())
    .then(data => {
      console.log('Upload response:', data);
      setShowYoutubeUploadModal(false);
      // Handle success (e.g., show a success message)
    })
    .catch(error => {
      console.error('Error uploading to YouTube:', error);
      // Handle error (e.g., show an error message)
    });
  closeYoutubeUploadModal();
};  

const YouTubeUploadModal = ({ isOpen, onRequestClose, onUpload, initialData, clipUrl }) => {
  const [formData, setFormData] = useState(initialData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpload(formData);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="youtube-upload-modal"
      overlayClassName="youtube-upload-overlay"
    >
      <div className="modal-header2 close-container2">
        <FontAwesomeIcon 
          icon={faOctagonXmark} 
          className="modal-close-icon" 
          style={{ color: 'red', marginLeft: '20px' }} 
          onClick={onRequestClose}
        />
        <h2 style={{ marginLeft: '10px' }}>Upload to YouTube</h2>
      </div>
      <form onSubmit={handleSubmit} className="youtube-upload-form">
        <label>
          Video Title:
          <input 
            type="text" 
            name="video_title" 
            value={formData.video_title} 
            onChange={handleInputChange} 
            required 
          />
        </label>
        <label>
          Video Description:
          <textarea 
            name="video_description" 
            value={formData.video_description} 
            onChange={handleInputChange} 
            required 
          />
        </label>
        <label>
          Video Type:
          <select 
            name="video_type" 
            value={formData.video_type} 
            onChange={handleInputChange}
          >
            <option value="short">Short</option>
            <option value="regular">Regular</option>
          </select>
        </label>
        <label>
          Schedule Time:
          <select 
            name="schedule_time" 
            value={formData.schedule_time} 
            onChange={handleInputChange}
          >
            <option value="now">Now</option>
            <option value="1h">1 hour from now</option>
            <option value="6h">6 hours from now</option>
            <option value="12h">12 hours from now</option>
            <option value="24h">24 hours from now</option>
          </select>
        </label>
        <label>
          Hashtags CSV:
          <textarea 
            name="hashtags" 
            value={formData.hashtags} 
            onChange={handleInputChange} 
            optional 
          />
        </label>
        <label>
          Channel:
          <select 
            name="channel" 
            value={formData.channel} 
            onChange={handleInputChange}
          >
            <option value="dungeon">Dungeon</option>
            <option value="ac3">AC3</option>
            <option value="mookie">Mookie</option>
            <option value="hooligans">Hooligans</option>
          </select>
        </label>
        <button type="submit">Upload</button>
      </form>
    </Modal>
  );
};



  ReactGA.send({ hitType: "pageview", page: "/transcripts", title: "search results" });



  useEffect(() => {
    // This effect runs when `selectedChannelNicknames` changes.
    // It filters the `results` based on the selected channel nicknames and updates `filteredResults`.
    const filtered = results.filter(result =>
      selectedChannelNicknames.has(result.channel_nickname)
    );
    setFilteredResults(filtered);
    setCurrentPage(1); // Reset to the first page
  }, [selectedChannelNicknames, results]);

  useEffect(() => {
  // Initialize selectedAuthorIds with allDistinctAuthorIds when it's available
  setSelectedChannelNicknames(new Set(allDistinctChannelNicknames));
}, [allDistinctChannelNicknames]);

useEffect(() => {
  const { searchText: innerSearchText, searchText2: innerSearchText2, channel_nickname, startDate, endDate } = searchText;
  const channelData = JSON.parse(localStorage.getItem('channel-data') || '{"results":[]}');
//  console.log('channelData', channelData);
//  console.log('input channnel_nickname:', channel_nickname);

  if (innerSearchText || innerSearchText2) {
    try {
      let channelLookupId;
      
      if (channel_nickname === "all") {
        // If channel_nickname is "all", concatenate all channel_lookup_ids in comma-separated form
        //channelLookupId = channelData.results.map(entry => entry[0]).join(',');
        channelLookupId = 0;
        //console.log('all detected, new channelLookupId:', channelLookupId);
      } else {
        // Find the channel_lookup_id for the specified channel_nickname
        const entry = channelData.results.find(entry => entry[2] === channel_nickname);
        if (entry) {
          channelLookupId = entry[0];
          //console.log('all not detected, new channelLookupId:', channelLookupId);
        } else {
          console.error('Channel lookup_id not found for channel_nickname:', channel_nickname);
          return; // Exit early if channel_nickname is not found
        }
      }

      // Fetch data here...
      fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/search?u=${user}&t=transcript&m=precision&c=${channelLookupId}&s=${innerSearchText}&s2=${innerSearchText2}&b=${startDate}&e=${endDate}&l=1000`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          if (data.results && Array.isArray(data.results.transcript)) {
            const transcriptResults = data.results.transcript;
            if (transcriptResults.length) {
              const distinctChannelNicknames = new Set();
              data.results.transcript.forEach(item => {
                  distinctChannelNicknames.add(item.channel_nickname);
              });
              //console.log('i got results and here is the channel_nickname values:',distinctChannelNicknames)
              setAllDistinctChannelNicknames(Array.from(distinctChannelNicknames));
              //console.log('now channel nicknames in array format:',allDistinctChannelNicknames)

              setResults(transcriptResults);
              setFilteredResults(transcriptResults);
              setCurrentPage(1);
              setFilterText('');
            } else {
              console.error('No results found:', transcriptResults);
              setResults([]);
              setFilteredResults([]);
            }
          } else {
            console.error('Invalid data structure:', data);
            setResults([]);
            setFilteredResults([]);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setResults([]);
          setFilteredResults([]);
        });
      
    } catch (error) {
      console.error('Error in try block:', error);
      // Handle the error here, if needed.
    }
  }

  // ... Other code ...
}, [searchText, searchText2]);





const handleFilterChange = (event) => {
  //console.log('inside handleFilterChange')
  //console.log('event:',event)
  //console.log('event.target.value:',event.target.value)
  const newFilterText = event.target.value.toLowerCase();
  //console.log('newFilterText:',newFilterText)
  setCurrentPage(1);
  //console.log('results:',results)
  if (newFilterText) {
    setFilteredResults(
      results.filter(result => 
    result.highlight && result.highlight.toLowerCase().includes(newFilterText)
  )
    );
  } else {
    setFilteredResults(results);
  }
  setFilterText(newFilterText);
};

  // Calculate pagination values
  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  //const currentResults = results.slice(indexOfFirstResult, indexOfLastResult);

const handleRowClick = (result, event) => {
  //console.log('handleRowClick trying ..',result)
  if (event.target.tagName !== 'A' && event.target.tagName !== 'BUTTON') {
    const timestampedUrl = `https://youtu.be/${result.video_id}?t=${result.start_time}`;
    setVideoUrl(timestampedUrl);
  }
};

// Add this useEffect
useEffect(() => {
  if (videoUrl) {
    setShowVideoModal(true);
    console.log('videoUrl:',videoUrl)
  }
}, [videoUrl]);

const convertTimeToSeconds = (time) => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};


// Create new state variable for clip URL
const [clipUrl, setClipUrl] = useState(null);
// Create new state variable for showing/hiding the clip player modal
const [showClipModal, setShowClipModal] = useState(false);
const [showArchivalModal, setShowArchivalModal] = useState(false);

useEffect(() => {
  if (showClipModal) {
    setClipModalOpen(false);
  }
}, [showClipModal]);

useEffect(() => {
  if (showArchivalModal) {
    setArchivalModalOpen(false);
  }
}, [showArchivalModal]);


  const handleChannelNicknameCheckboxChange = (channelNickname) => {
    setSelectedChannelNicknames(prevSelectedChannelNicknames => {
      const updatedSelectedChannelNicknames = new Set(prevSelectedChannelNicknames);

      if (updatedSelectedChannelNicknames.has(channelNickname)) {
        updatedSelectedChannelNicknames.delete(channelNickname);
      } else {
        updatedSelectedChannelNicknames.add(channelNickname);
      }

      // No need to filter results here directly; the useEffect hook will handle it.
      return updatedSelectedChannelNicknames;
    });
  };

const handleClipGeneration = (result) => {
  if (result) {
    setIsLoading(true);
    //console.log('result:',result)
  const video_id = result.video_id;
  const channel = result.channel_nickname;
  const start_time = result.start_time;
    const pre_offset_converted_start_time = start_time;
    const adjusted_start_time = pre_offset_converted_start_time + Number(startTimeOffset);
    const duration = clipDuration;
    // Construct the URL with query parameters
    const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/clip?channel=${channel}&videoid=${video_id}&start=${adjusted_start_time}&duration=${duration}`;

    //console.log('handleClipGeneration: video_id',video_id,'channel',channel)
    //console.log('start_time',start_time,'pre_offset_converted_start_time',pre_offset_converted_start_time)
    //console.log('adjusted_start_time',adjusted_start_time,'duration',duration)
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setClipUrl(data.url);  // Store the presigned URL in the state variable
        setShowClipModal(true);  // Open the clip player modal
        setIsLoading(false); // Set loading state to false when clip has been generated
      })
      .catch((error) => {
        console.error('Error generating clip:', error);
        setIsLoading(false); // Also set loading state to false in case of error
      });
  }
};


const handleDownloadClip = (event) => {
  event.stopPropagation();
  const link = document.createElement('a');
  link.href = clipUrl;
  link.download = `${selectedResult.video_id}.mp4`;  // Or any filename you like
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const handleViewClip = (clipFilename) => {
    fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/get_clip?filename=${clipFilename}`)
        .then((response) => response.json())
        .then((data) => {
            window.open(data.url);
        })
        .catch((error) => {
            console.error('Error viewing clip:', error);
        });
};

const handleClipButtonClick = (event, result) => {
  event.stopPropagation(); // Prevent the event from bubbling up to the row's onClick handler
  setSelectedResult(result);
  setClipModalOpen(true);
};

// Do similar logging for the other functions

const handleTranscriptGeneration = (event, result) => {
  //console.log('handleTranscriptGeneration called with result:',result)
  event.stopPropagation();
  const video_id = result.video_id;
  const start_time = result.start_time;
  const adjusted_start_time = start_time;
  //const adjusted_start_time = convertTimeToSeconds(start_time);
  const filename = `transcript_${video_id}_${start_time}.txt`; // Set the desired filename

  const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/get_transcript?videoid=${video_id}&start=${adjusted_start_time}`;
  fetch(url)
    .then((response) => {
      response.blob().then((blob) => {
        // Trigger the file download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
      });
    })
    .catch((error) => {
      console.error('Error downloading file:', error);
    });
};


const handleArchivalRequest = (event, result) => {
  event.stopPropagation();
  setSelectedResult(result);
  setRequestNote(defaultRequestNote); // Clear requestNote when modal is opened
  setResponseMessage(''); // Clear responseMessage when modal is opened
  setResponseStatus(''); // Clear responseStatus when modal is opened
  setArchivalModalOpen(true);

};
const handleArchivalRequestGeneration = (result, requestNote) => {
  const video_id = result.video_id;
  const channel = result.channel_nickname; // channel_nickname
  const request_note = requestNote;
  const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/request-archival?videoid=${video_id}&username=${user}&channel_nickname=${channel}&note=${request_note}`;

  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Parse the JSON response
    })
    .then((data) => {
      console.log('archival request response:', data);
      // Access data.message and data.status here
      const { message, status } = data;

      // Set response message and status in state
      setResponseMessage(message);
      setResponseStatus(status);

      // Process the response message and status as needed
      if (status === 'success') {
        // Handle success case
        console.log('archival request successful:', message);
      } else if (status === 'duplicate_video_id') {
        // Handle duplicate video ID case
        console.log('archival request rejected due to duplicate_video_id');
      } else {
        // Handle other cases
        console.log('archival request failed due to Unknown status:', status);
      }
    })
    .catch((error) => {
      console.error('Error requesting archival:', error);
    });
};


  // Handle row hover events
  const handleRowMouseEnter = (index) => {
    setSelectedRow(index);
  };

  const handleRowMouseLeave = () => {
    setSelectedRow(null);
  };

  // Change page
  /*const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    const maxPage = Math.ceil(filteredResults.length / resultsPerPage); // Calculate max pages from filtered results
    if (currentPage < maxPage) {
        setCurrentPage(currentPage + 1);
    }
};*/

const handlePreviousPageClick = (event) => {
  event.preventDefault(); // Prevents the default action of the event.
  event.stopPropagation(); // Stops the event from propagating up the DOM.
  if (currentPage > 1) {
    setCurrentPage(currentPage - 1);
  }
};

const handlePreviousPageBeginningClick = (event) => {
  event.preventDefault(); // Prevents the default action of the event.
  event.stopPropagation(); // Stops the event from propagating up the DOM.
  if (currentPage > 1) {
    setCurrentPage(1);
  }
};

const handleNextPageClick = (event) => {
  event.preventDefault(); // Prevents the default action of the event.
  event.stopPropagation(); // Stops the event from propagating up the DOM.
  const maxPage = Math.ceil(filteredResults.length / resultsPerPage);
  if (currentPage < maxPage) {
    setCurrentPage(currentPage + 1);
  }
};

const handleNextPageEndClick = (event) => {
  event.preventDefault(); // Prevents the default action of the event.
  event.stopPropagation(); // Stops the event from propagating up the DOM.
  const maxPage = Math.ceil(filteredResults.length / resultsPerPage);
  setCurrentPage(maxPage);
};



  const closeVideoModal = () => {
    setShowVideoModal(false);
  };

  const extractVideoId = (url) => {
    const videoUrl = new URL(url);
    const videoId = videoUrl.searchParams.get('v');
    return videoId;
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      closeVideoModal();
    }
  };

 const highlightFilterText = (text, searchText) => {
  const parts = text.split(new RegExp(`(${filterText})`, 'gi'));
  return (
    <span>
      {parts.map((part, i) => 
        part.toLowerCase() === filterText.toLowerCase() ?
          <span key={i} className='highlight-filter-text'>{part}</span> : 
          part
      )}
    </span>
  );
}

 const highlightText = (text, searchText) => {
  const parts = text.split(new RegExp(`(${searchText})`, 'gi'));
  return (
    <span>
      {parts.map((part, i) => 
        part.toLowerCase() === searchText.toLowerCase() ?
          <span key={i} className='highlight-text'>{part}</span> : 
          part
      )}
    </span>
  );
}

const applyHighlights = (text) => {
  if (!searchText || !searchText.searchText) return text;
  let parts = text.split(new RegExp(`(${searchText.searchText})`, 'gi'));
  return (
    <span>
      {parts.map((part, i) => 
        part.toLowerCase() === searchText.searchText.toLowerCase() ?
          <span key={i} className='highlight-text'>
            {highlightFilterText(part, filterText)}
          </span> : 
          highlightFilterText(part, filterText)
      )}
    </span>
  );
}

const formatTimestamp = (videoId, seconds) => {
  const pad = (num) => num.toString().padStart(2, '0');
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const sec = seconds % 60;

  const timestampUrl = `https://www.youtube.com/watch?v=${videoId}&t=${seconds}s`;

  return (
    <>
      <a href={timestampUrl} target="_blank" rel="noopener noreferrer">
        timestamp
      </a>
      {' '}@ {pad(hours)}:{pad(minutes)}:{pad(sec)}
    </>
  );
};


  const currentResults = filteredResults.slice(indexOfFirstResult, indexOfLastResult);


  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const videoModalOptions = {
    height: '480px',
    width: '854px',
    controls: true,
    playing: true,
  };

  
return (
    <div> 
        <div className="flex-container">
            <input
                type="text"
                value={filterText}
                onInput={handleFilterChange}
                className="filter-input"
                placeholder="filter result"
            />
            {filteredResults.length > 0 ? (
                <div className="results-info" style={{ marginTop: '10px' }}>
                    <p>{filteredResults.length} results</p>
                </div>
            ) : (
                <div className="results-info" style={{ marginTop: '10px' }}>
                    <p style={{color: 'red'}}>No results found</p>
                </div>
            )}
        </div>
        {allDistinctChannelNicknames.length > 0 && (
<div>
          {allDistinctChannelNicknames.length > 0 && (
              <div style={{display: 'flex', flexWrap: 'wrap', gap: '15px', paddingLeft: '25px'}}>
                  {allDistinctChannelNicknames.map((channelNickname, index) => (
                      <label key={index} style={{display: 'flex', alignItems: 'center'}}>
                          <input
                              type="checkbox"
                              checked={selectedChannelNicknames.has(channelNickname)}
                              onChange={() => handleChannelNicknameCheckboxChange(channelNickname)}
                          />
                          {channelNickname}
                      </label>
                  ))}
      
    </div>
  )}
</div>
        )}
<br />
<div className="pagination-container">
      <div className="pagination">
      </div>
<div style={{ userSelect: 'none', display: 'inline-block' }}>

      <FontAwesomeIcon 
        icon={faArrowLeftToLine } 
        className="fa-thin icon-hover" 
        onClick={handlePreviousPageBeginningClick} disabled={currentPage === 1}
        disabled={currentPage === 1}
        style={{ 
          color: currentPage == 1 ? 'grey' : 'blue', 
          display: 'inline-block', 
          marginLeft: '10px'
        }} 
      />
      <FontAwesomeIcon 
        icon={faChevronLeft} 
        className="fa-thin icon-hover" 
        onClick={handlePreviousPageClick} disabled={currentPage === 1}
        disabled={currentPage === 1}
        style={{ 
          color: currentPage == 1 ? 'grey' : 'blue', 
          display: 'inline-block', 
          marginLeft: '10px'
        }} 
      />

        &nbsp; {currentPage} / {totalPagesFiltered}

      <FontAwesomeIcon 
        icon={faChevronRight} 
        className="fa-thin icon-hover" 
        disabled={currentPage >= totalPagesFiltered}
        onClick={handleNextPageClick} disabled={currentPage >= totalPagesFiltered}
        style={{ 
          color: currentPage + 1 > totalPagesFiltered ? 'grey' : 'blue',
          display: 'inline-block', 
          marginLeft: '10px'
        }} 
      />

      <FontAwesomeIcon 
        icon={faArrowRightToLine } 
        className="fa-thin icon-hover" 
        onClick={handleNextPageEndClick}
        style={{ 
          color: currentPage + 1 > totalPagesFiltered ? 'grey' : 'blue',
          display: 'inline-block', 
          marginLeft: '10px'
        }} 
      />



</div>
        </div>

        <div className="my-table-container" style={{maxHeight: "600px", overflowY: "auto"}}>

            <table className="data">
                <thead>
                    <tr>
                        <th style={{ width: '10%' }}>channel</th>
                        <th style={{ width: '10%' }}>video_id</th>
                        <th style={{ width: '5%' }}>thumbnail</th>
                        <th style={{ width: '15%' }}>publishedAt</th>
                        <th style={{ width: '25%' }}>video_title</th>
                        <th style={{ width: '10%' }}>start_time</th>
                        <th style={{ width: '20%' }}>text</th>
                        <th style={{ width: '5%' }}>timestamped_url</th>
                        <th style={{ width: '10%' }}>actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentResults.map((result, index) => (
                        <tr
                            key={index}
                            className={selectedRow === index ? 'highlighted-row' : ''}
                            onClick={(event) => handleRowClick(result, event)}
                            onMouseEnter={() => handleRowMouseEnter(index)}
                            onMouseLeave={handleRowMouseLeave}
                        >
      <td>{result.channel_nickname}</td>
      <td>{result.video_id}</td>
      <td><img src={result.video_thumbnail_link} alt="Thumbnail" /></td>
      <td>{result.video_timestamp_pst}</td>
      <td>{result.video_title}</td>
      <td>{formatTimestamp(result.video_id, result.start_time)}</td>
      <td>{applyHighlights(result.highlight)}</td>
                            <td>
                            <a href={`https://youtu.be/${result.video_id}?t=${result.start_time}`} target="_blank" rel="noopener noreferrer">
                                youtube</a>
                            </td>
                            <td>
        <div className="transcript-button">
        <FontAwesomeIcon 
          icon={faScissors } 
          className="fa-duotone" 
          title="generate-clip"
          onClick={(event) => handleClipButtonClick(event, result)} 
          style={{ 
            color: 'blue',
            display: 'inline-block', 
            marginLeft: '10px'
          }} 
        />
        </div>
        <div className="transcript-button">
        <FontAwesomeIcon 
          icon={faScrollOld  } 
          className="fa-duotone" 
          title="request-transcript"
          onClick={(event) => handleTranscriptGeneration(event, result)}
          style={{ 
            color: 'blue',
            display: 'inline-block', 
            marginLeft: '10px'
          }} 
        />                             
        </div>
        <div className="transcript-button">
        <FontAwesomeIcon 
          icon={faDownload} 
          className="fa-duotone" 
          title="request-archival-download"
          onClick={(event) => handleArchivalRequest(event, result)}
          style={{ 
            color: 'blue',
            display: 'inline-block', 
            marginLeft: '10px'
          }} 
        />                             
        </div>               
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

        {showVideoModal && (
            <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                <div className="modal-dialog" role="document">
                    <div className="modal-body"></div>
                        <div className="modal-content">
                            <div className="modal-header close-container" onClick={closeVideoModal} >
                                <h6 className="modal-title">hit escape to close</h6>          
                                    <FontAwesomeIcon icon={faOctagonXmark} className="modal-close-icon" />
                            </div>
                            <ReactPlayer 
                                url={videoUrl}
                                width={videoModalOptions.width}
                                height={videoModalOptions.height}
                                controls={true}
                                playing={true}
                                onError={(e) => {
                                    console.error('Video error', e);
                                    closeVideoModal();
                                    window.open(videoUrl, '_blank');
                                }}
                            />
                        </div>
                    </div>
                </div>
        )}


      {selectedResult && (
        <Modal 
          isOpen={clipModalOpen} 
          onRequestClose={() => setClipModalOpen(false)}
          className="clip-modal-content"
          overlayClassName="clip-modal-overlay"
        >
          <h6>clip {selectedResult.video_title}</h6>
          <label>
            offset--s--(secs)
            <input type="text" value={startTimeOffset} onChange={(e) => setStartTimeOffset(e.target.value)} />
          </label>
          <label>
            duration-(secs)
            <input type="number" value={clipDuration} onChange={(e) => setClipDuration(Number(e.target.value))} />
          </label>
          <button onClick={() => handleClipGeneration(selectedResult)}> generate clip</button>
          {isLoading && <p>please wait...</p>}
        </Modal>
      )}


{selectedResult && (
<div>
  <Modal className="clip-modal-content" isOpen={ArchivalModalOpen} onRequestClose={() => setArchivalModalOpen(false)}>
    {selectedResult && <h6>ARCHIVAL REQUEST {selectedResult.video_title}</h6>}
    <label>
      Add a request note (optional) 
      <br />
      <input type="text" value={requestNote} onChange={(e) => setRequestNote(e.target.value)} />
    </label>

    <button onClick={() => handleArchivalRequestGeneration(selectedResult, requestNote, channel_nickname)}> submit</button>

    {isLoading && <p>please wait...</p>}
        <button onClick={() => setArchivalModalOpen(false)}>close</button>
    {responseMessage && (
      <div>
        <p>{responseMessage} : {responseStatus}</p>
        
      </div>
    )}
  </Modal>
</div>
)}

      {showClipModal && (
        <Modal
          isOpen={showClipModal}
          onRequestClose={() => setShowClipModal(false)}
          className="react-modal2"
          overlayClassName="clip-modal-overlay"
        >
          <div className="modal-header2 close-container2">
            <FontAwesomeIcon 
              icon={faOctagonXmark} 
              className="modal-close-icon" 
              style={{ color: 'red', marginLeft: '20px' }} 
              onClick={() => setShowClipModal(false)}
            />
            {!isLoading && 
              <>
                <FontAwesomeIcon 
                  icon={faDownload} 
                  className="modal-download-icon" 
                  style={{ color: 'blue', marginLeft: '10px', marginRight: '10px' }} 
                  onClick={handleDownloadClip} 
                />
                <FontAwesomeIcon 
                  icon={faUpload} 
                  className="modal-upload-icon" 
                  style={{ color: 'green', marginLeft: '10px', marginRight: '10px' }} 
                  onClick={handleYoutubeUploadClick} 
                />
              </>
            }
          </div>
          {isLoading ? (
            <p>Generating - please wait...</p>
          ) : (
            <ReactPlayer url={clipUrl} controls={true} playing={true} volume={0.90} width='100%' />
          )}
        </Modal>
      
      )}

      {showYoutubeUploadModal && ReactDOM.createPortal(
        <YouTubeUploadModal 
          isOpen={showYoutubeUploadModal}
          onRequestClose={closeYoutubeUploadModal}
          onUpload={uploadToYoutube}
          initialData={youtubeUploadData}
          clipUrl={clipUrl}
        />,
        document.body
      )}

)}
{showArchivalModal && (
  <Modal 
    isOpen={showArchivalModal} 
    onRequestClose={() => {
      setRequestNote(defaultRequestNote); // Clear requestNote when modal is closed
      setResponseMessage(''); // Clear responseMessage when modal is closed
      setResponseStatus(''); // Clear responseStatus when modal is closed
      setShowArchivalModal(false);
    }}
    className="react-modal2"
  >
    <div className="modal-header2 close-container2">
      <FontAwesomeIcon 
        icon={faOctagonXmark} 
        className="modal-close-icon" 
        style={{ color: 'red', marginLeft: '20px' }} 
        onClick={() => setShowArchivalModal(false)}
      />
      {!isLoading && 
        <FontAwesomeIcon 
          icon={faDownload} 
          className="modal-download-icon" 
          style={{ color: 'blue', marginLeft: '10px', marginRight: '10px' }} 
          onClick={handleArchivalRequest} 
        />
      }
    </div>
    {isLoading ? (
      <p>Requesting.. please wait</p>
    ) : (
      <p>Requested.. please check the archivals page soon for a download link....</p>
    )}
  </Modal>
)}
</div>
);
}


export default SearchResults;



