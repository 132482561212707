import React, { useState, useContext, Fragment, createContext, useEffect } from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
//import routeStreams from './routes/streams/streams.js';
import routeStreams2 from './routes/streams/streams2.js';
import Superchat from './Superchat';
import SuperchatCompare from './SuperchatCompare';
import Home from './Home';
import CustomNavbar from './Navbar';
import TranscriptSearch from './TranscriptSearch';
import SearchResults from './SearchResults';
import UserSearchResults from './UserSearchResults';
import UserLookup from './routes/users/users.js';
//import Users from './users';
import DonorSummary from './DonorSummary';
import LoginForm from './LoginForm';
import VideoChats from './VideoChats';
import Mentions from './routes/mentions/mentions'
import Subscriptions from './subscriptions';
import SearchVideos from './videosearch';
import SearchV2 from './routes/search/searchV2';
import SearchV3 from './routes/search/searchV3';
import Channels from './channels';
import Archivals from './archivals';
import axios from 'axios';
import ReactGA from "react-ga4";

import { AuthContext, AuthProvider } from './AuthContext'; // Import AuthProvider

import {
    enable as enableDarkMode,
    disable as disableDarkMode,
    auto as followSystemColorScheme,
    exportGeneratedCSS as collectCSS,
    isEnabled as isDarkReaderEnabled
} from 'darkreader';



const isIframe = window.location !== window.parent.location;
const isLocalEnv = process.env.REACT_APP_BYPASS_AUTH === 'true';

//export const AuthContext = createContext();

const TranscriptPage = () => {
  const [searchText, setSearchText] = useState('');

  const handleSearch = (inputText) => {
    setSearchText(inputText);
  };

  return (
    <div>
      <TranscriptSearch onSearch={handleSearch} />
      <SearchResults searchText={searchText} />
    </div>
  );
};
/*
const UsersPage = () => {
  const [searchParams, setSearchParams] = useState({
    searchTextUsername: '',
    searchTextSearchText: '',
    channel_nickname: 'all', // default or another appropriate value
    startDate: '', // default or another appropriate value
    endDate: '' // default or another appropriate value
  });

  const handleSearch = (params) => {
    setSearchParams(params);
  };

  return (
    <div>
      <UserSearch onSearch={handleSearch} />
      <UserSearchResults {...searchParams} />
    </div>
  );
};*/

export function App() {
  ReactGA.initialize("G-HTHS2WHL7R");
  //const [user, setUser] = useState(isLocalEnv ? 'localUser' : null);


// CACHE DATA ----------------------------------------------------------------------
const CACHE_EXPIRY_TIME = 6 * 60 * 60 * 1000; // 6 hours in milliseconds
const [isLoading, setIsLoading] = useState(false);

const fetchChannels = async () => {
  setIsLoading(true);
  const cachedData = localStorage.getItem('channel-data');
  const cachedTimestamp = localStorage.getItem('channel-data-timestamp');

  if (cachedData && cachedTimestamp) {
    const now = new Date().getTime();
    const timeElapsed = now - parseInt(cachedTimestamp, 10);

    if (timeElapsed < CACHE_EXPIRY_TIME) {
      const parsedData = JSON.parse(cachedData);
      //setUsers(parsedData); // Set users state with cached data
      setIsLoading(false);
      return;
    }
  }

  try {
    const response = await fetch('https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/get-cached-channels?username=mookie');
    const data = await response.json();
    localStorage.setItem('channel-data', JSON.stringify(data));
    localStorage.setItem('channel-data-timestamp', new Date().getTime().toString());
    //setUsers(data); // Set users state with fetched data
  } catch (error) {
    console.error('Error fetching channel-data:', error);
  }

  setIsLoading(false); // Ensure loading is stopped in all scenarios
};


const fetchUsernames = async () => {
  setIsLoading(true);
  const cachedData = localStorage.getItem('superchat-usernames');
  const cachedTimestamp = localStorage.getItem('superchat-usernames-timestamp');

  if (cachedData && cachedTimestamp) {
    const now = new Date().getTime();
    const timeElapsed = now - parseInt(cachedTimestamp, 10);

    if (timeElapsed < CACHE_EXPIRY_TIME) {
      const parsedData = JSON.parse(cachedData);
      //setUsers(parsedData); // Set users state with cached data
      setIsLoading(false);
      return;
    }
  }

  try {
    const response = await fetch('https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/get-cached-usernames?username=mookie');
    const data = await response.json();
    localStorage.setItem('superchat-usernames', JSON.stringify(data));
    localStorage.setItem('superchat-usernames-timestamp', new Date().getTime().toString());
    //setUsers(data); // Set users state with fetched data
  } catch (error) {
    console.error('Error fetching usernames:', error);
  }

  setIsLoading(false); // Ensure loading is stopped in all scenarios
};

useEffect(() => {
  const timeoutId = setTimeout(() => {
    enableDarkMode({
      brightness: 100,
      contrast: 90,
      sepia: 10,
    });
  }, 100); // 100ms timeout, adjust as needed

  return () => {
    clearTimeout(timeoutId);
    disableDarkMode();
  };
}, []);

useEffect(() => {
  // Call fetchUsernames when component mounts
  fetchUsernames();
  fetchChannels();

  // Set up an interval to call fetchUsernames every 5 minutes
  const intervalId = setInterval(fetchUsernames, 300000); // 300000ms = 5 minutes

  // Clean up interval on component unmount
  return () => clearInterval(intervalId);
}, []);

  return (
     <AuthProvider> {/* Use AuthProvider */}
      <Router>
        <Fragment>
          <CustomNavbar isIframe={isIframe} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Superchat" element={<SuperchatWithAuth />} /> 
            <Route path="/SuperchatCompare" element={<SuperchatCompareWithAuth />} /> 
            <Route path="/transcripts" element={<TranscriptWithAuth />} /> 
            <Route path="/users" element={<UsersWithAuth />} />             
            <Route path="/streams2" element={<Streams2WithAuth />} />
            <Route path="/subscriptions" element={<SubscriptionsWithAuth />} />
            <Route path="/videos" element={<SearchVideosWithAuth />} />
            <Route path="/searchv2" element={<SearchV2WithAuth />} />
            <Route path="/searchv3" element={<SearchV3WithAuth />} />
            <Route path="/channels" element={<ChannelsWithAuth />} />
            <Route path="/DonorSummary/:donorId" element={<DonorSummary />} />
            <Route path="/VideoChats/:videoId" element={<VideoChats />} />
            <Route path="/mentions" element={<MentionsWithAuth />} />
            <Route path="/archivals" element={<ArchivalsWithAuth />} />
            
            <Route path="/login" element={<LoginForm />} />
          </Routes>
        </Fragment>
      </Router>
    </AuthProvider>
  );
}

function withAuth(Component) {
  return function AuthenticatedComponent() {
    const { user } = useContext(AuthContext);

    if (!user && process.env.REACT_APP_BYPASS_AUTH !== 'true') {
      return <Navigate to="/login" replace />;
    }

    return <Component />;
  };
}

const SuperchatWithAuth = withAuth(Superchat);
const SuperchatCompareWithAuth = withAuth(SuperchatCompare);
const TranscriptWithAuth = withAuth(TranscriptPage);
const UsersWithAuth = withAuth(UserLookup);
const Streams2WithAuth = withAuth(routeStreams2);

const SubscriptionsWithAuth = withAuth(Subscriptions);
const SearchVideosWithAuth = withAuth(SearchVideos);
const SearchV2WithAuth = withAuth(SearchV2);
const SearchV3WithAuth = withAuth(SearchV3);
const ChannelsWithAuth = withAuth(Channels);
const MentionsWithAuth = withAuth(Mentions);
const ArchivalsWithAuth = withAuth(Archivals);

export default App;