/*BACKUP UserLookup.js*/

import React, { useState, useEffect, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import '../../tables.css';
import { AuthContext, AuthProvider } from '../../AuthContext'; 

Chart.register(...registerables);

const timeRanges = {
  '3 month': 3,
  '6 month': 6,
  '1 year': 12,
  'All': Infinity
};

const UserLookup = () => {
  const { user } = useContext(AuthContext);
  const [userToLookup, setUserToLookup] = useState('');
  const [selectedTimeRange, setSelectedTimeRange] = useState('3 month');
  const [fullData, setFullData] = useState([]);
  const [chartData, setChartData] = useState({ datasets: [] });
  const [tableRows, setTableRows] = useState([]);
  const [aggregatedData, setAggregatedData] = useState([]);
  const [totals, setTotals] = useState({ totalChats: 0, sumSuperchats: 0 });
  const [channelNicknames, setChannelNicknames] = useState([]);
  const [selectedChannelNicknames, setSelectedChannelNicknames] = useState(new Set());


const [allDistinctAuthorIds, setAllDistinctAuthorIds] = useState([]);
const [initialSelectedAuthorIds, setInitialSelectedAuthorIds] = useState(new Set()); // Define initialSelectedAuthorIds
const [selectedAuthorIds, setSelectedAuthorIds] = useState(initialSelectedAuthorIds);
const [authorId, setAuthorId] = useState(null);  
const CACHE_EXPIRY_TIME = 6 * 60 * 60 * 1000; // 6 hours in milliseconds
const [isLoading, setIsLoading] = useState(false);

  const [showDonorSummary, setShowDonorSummary] = useState(true);
  const [donorSummaryData, setDonorSummaryData] = useState(null);

  const [filteredUsernames, setFilteredUsernames] = useState([]);
  const [users, setUsers] = useState([]);

  const [filterText, setFilterText] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });



 useEffect(() => {
    // Load usernames from localStorage
    const storedUsernames = localStorage.getItem('superchat-usernames');
    if (storedUsernames) {
      const usernamesObject = JSON.parse(storedUsernames);
      //console.log('usernamesObject:',usernamesObject)
      setUsers(usernamesObject.results);
    }
  }, []);

  useEffect(() => {
  // Initialize selectedAuthorIds with allDistinctAuthorIds when it's available
  setSelectedAuthorIds(new Set(allDistinctAuthorIds));
}, [allDistinctAuthorIds]);





const generateAuthorIdLink = (authorId) => {
  return `https://youtube.com/channel/${authorId}`;
};


  const renderDonorSummaryIframe = () => {
  if (showDonorSummary && authorId) {
    const donorSummaryUrl = `/DonorSummary/${authorId}`;
    return (
      <iframe 
        src={donorSummaryUrl} 
        style={{ width: '100%', height: '400px', border: 'none' }}
        title="Donor Summary"
      />
    );
  }
  return null;
};



  const [chartOptions, setChartOptions] = useState({
    scales: {
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        beginAtZero: true,
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: 'superchat_revenue'
        },
        ticks: {
          callback: function (value, index, values) {
            // Adjust the value here as needed
            return value.toFixed(0);
          }
        }
      },
    }
  });

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    borderColor: '1px solid #222f4a'
  };

  const thStyle = {
    backgroundColor: '#213454',
    fontWeight: 'bold',
    textAlign: 'left',
    padding: '8px',
    borderBottom: '1px solid #222f4a',
  };

  const tdStyle = {
    padding: '8px',
    borderBottom: '1px solid #2c3038',
    borderLeft: '1px solid #222f4a',
    borderRight: '1px solid #222f4a',
    // You can add conditional styling based on row data as needed
  };

  const createTableRows = () => {
    const aggregatedData = aggregateDataForTable(fullData);
    return aggregatedData.map(item => ({
      month: item.month,
      totalChats: item.total_chats,
      sumSuperchats: item.sum_superchats
    }));
  };

const handleInputChange = (e) => {
  setUserToLookup(e.target.value);

  if (Array.isArray(users)) { 
    const filtered = users.filter(username =>
      username.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredUsernames(filtered);
  } else {
    console.error('Expected users to be an array, but got:', users);
  }
};

const handleUsernameClick = (username) => {
  setUserToLookup(username); // Update the input field
  console.log('userToLookup:',userToLookup)
  setFilteredUsernames([]); // Clear the suggestions
  fetchData(username); // Fetch data with the clicked username
};

/*const handleGoClick = (username = userToLookup) => {
  //fetchData(username);
  setFilteredUsernames([]);
};*/
// Adjust the handleGoClick to find the authorId from the username and then fetch data
const handleGoClick = () => {
  // Find the authorId corresponding to the username
  console.log('users:',users)
  const user = users.find(u => u.username === userToLookup);
  if (user && user.authorId) {
    console.log('i have user and user.authorId:',user,user.authorId)
    fetchData(user.authorId); // Call fetchData with the authorId
  } else {
    console.error("No user found for the username provided or no authorId available.");
  }
};

const fetchData = async (username) => {
  setIsLoading(true);
  const url = 'https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/search-react-users-exact';
  const params = {
    search: username, // Use the passed username
    clientip: '127.0.0.1',
    username: user,
  };

  let resultsArray = [];

  try {
    const response = await fetch(`${url}?${new URLSearchParams(params)}`);
    const jsonData = await response.json();
    resultsArray = JSON.parse(jsonData.results);
    //console.log('resultsArray:',resultsArray)
    setFullData(resultsArray);
    console.log('fetched data in fetchData and set fullData :',fullData)

    // Initialize a set to store distinct author_id values
    const distinctAuthorIds = new Set();

    // Collect all distinct author_id values
    resultsArray.forEach(item => {
      distinctAuthorIds.add(item.author_id);
    });

    //console.log('distinctAuthorIds:', distinctAuthorIds)
    // Convert the set to an array and set it in the state
    setAllDistinctAuthorIds(Array.from(distinctAuthorIds));
    //console.log('allDistinctAuthorIds in array:',allDistinctAuthorIds)
    
    setIsLoading(false);
  } catch (error) {
    console.error('Error fetching data:', error);
    setIsLoading(false);
  }
};



  // new new new
  const handleChannelCheckboxChange = (nickname) => {
    setSelectedChannelNicknames(prev => {
      const newSelection = new Set(prev);
      if (newSelection.has(nickname)) {
        newSelection.delete(nickname);
      } else {
        newSelection.add(nickname);
      }
      return newSelection;
    });
  };

  const handleAuthorIdCheckboxChange = (authorId) => {
    setSelectedAuthorIds((prev) => {
      const newSelection = new Set(prev);
      if (newSelection.has(authorId)) {
        newSelection.delete(authorId);
      } else {
        newSelection.add(authorId);
      }
      return newSelection;
    });
  };

  const filterDataByTimeRange = (data, timeRange) => {
  // First, ensure that 'data' is an array
  //console.log('filterDataByTimeRange: data:',data)
  //console.log('filterDataByTimeRange: timeRange:',timeRange)
  if (!Array.isArray(data)) {
    console.error('Invalid data type for filtering:', data);
    return [];
  }


const generateAuthorIdLink = (authorId) => {
  return `https://youtube.com/channel/${authorId}`;
};


  const now = new Date();
  return data.filter(item => {
    const itemDate = new Date(item.date);
    let monthsDiff = (now.getFullYear() - itemDate.getFullYear()) * 12;
    monthsDiff += now.getMonth() - itemDate.getMonth();
    return timeRanges[timeRange] === Infinity || monthsDiff < timeRanges[timeRange];
  });
};

  const aggregateDataForTable = (data) => {
    const aggregated = data.reduce((acc, item) => {
      const month = new Date(item.date).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric' });
      if (!acc[month]) {
        acc[month] = { total_chats: 0, sum_superchats: 0 };
      }
      acc[month].total_chats += item.total_chats;
      acc[month].sum_superchats += item.sum_superchats;
      return acc;
    }, {});

    return Object.entries(aggregated).map(([month, { total_chats, sum_superchats }]) => ({
      month,
      total_chats,
      sum_superchats
    }));
  };

const aggregateData = (data) => {
  const aggregated = data.reduce((acc, item) => {
    //console.log('Processing item:', item);
    const author = item.author_name;
    const date = new Date(item.date).toLocaleDateString('en-US');
    //console.log('Formatted date:', date);

    if (!acc[author]) {
      acc[author] = {};
    }
    if (!acc[author][date]) {
      acc[author][date] = { total_chats: 0, sum_superchats: 0 };
    }
    acc[author][date].total_chats += item.total_chats;
    acc[author][date].sum_superchats += item.sum_superchats;
    
    return acc;
  }, {});

  //console.log('Aggregated Data:', aggregated);

  return Object.keys(aggregated).map(author => {
    const dates = Object.keys(aggregated[author]);
    const sortedDates = dates.sort((a, b) => new Date(a) - new Date(b));
    const totalChats = sortedDates.map(date => aggregated[author][date].total_chats);
    const sumSuperchats = sortedDates.map(date => aggregated[author][date].sum_superchats);

    return {
      author,
      dates: sortedDates,
      totalChats,
      sumSuperchats
    };
  });
};


const defaultChannelNicknames = new Set(["ac3", "wn", "timmy", "dungeon", "wn_live"]);
useEffect(() => {
  // Create a set of all distinct channel nicknames from fullData
  const allChannelNicknames = new Set(fullData.map(item => item.channel_nickname));
  setChannelNicknames([...allChannelNicknames]);

  // Initialize selectedChannelNicknames with default values
  const selectedNicknames = new Set(
    [...allChannelNicknames].filter(nickname => defaultChannelNicknames.has(nickname))
  );
  setSelectedChannelNicknames(selectedNicknames);
}, [fullData]);



useEffect(() => {
  // Ensure that 'fullData' is an array to avoid any potential issues
  //console.log('fullData at beginning of useEffect:',fullData)
  if (!Array.isArray(fullData)) {
    console.error('fullData is not an array:', fullData);
    //console.log('fullData is not an array:', fullData);
    return;
  }

  // First, filter the data by selected channel nicknames
  const dataFilteredByChannel = fullData.filter(item =>
    selectedChannelNicknames.has(item.channel_nickname)
  );

  // Then, filter the already channel-filtered data by the selected time range
  const dataFilteredByTimeRange = filterDataByTimeRange(dataFilteredByChannel, selectedTimeRange);
  //console.log('dataFilteredByTimeRange',dataFilteredByTimeRange)
  
    // Filter the data by selected author_id values
    const dataFilteredByAuthorIds = dataFilteredByTimeRange.filter((item) =>
      selectedAuthorIds.has(item.author_id)
    );


  // Now you can aggregate and update the table rows
  const aggregatedDataForTable = aggregateDataForTable(dataFilteredByAuthorIds);
  //console.log('aggregateDataForTable:',aggregatedDataForTable)
  const newTableRows = aggregatedDataForTable.map(item => ({
    month: item.month,
    totalChats: item.total_chats,
    sumSuperchats: item.sum_superchats
  }));

  const totalChatsSum = newTableRows.reduce((acc, row) => acc + row.totalChats, 0);
  const averageChats = newTableRows.length > 0 ? Math.round(totalChatsSum / newTableRows.length) : 0;
  const totalSuperchats = newTableRows.reduce((acc, row) => acc + row.sumSuperchats, 0);

  setTotals({ totalChats: averageChats, sumSuperchats: totalSuperchats });

  setTableRows(newTableRows);

  // And also update the chart data
  const aggregatedChartData = aggregateData(dataFilteredByAuthorIds);
  const chartDatasets = aggregatedChartData.map(authorData => ({
    label: `${authorData.author} - sum_superchat_revenue`,
    data: authorData.dates.map((date, index) => ({
      x: new Date(date).toISOString().split('T')[0], // Simplified date string
      y: authorData.sumSuperchats[index]
    })),
    fill: false,
    borderColor: "rgb(75, 192, 192)",
    yAxisID: 'y1',
  }));

  const labels = chartDatasets.length > 0
    ? chartDatasets[0].data.map(item => item.x)
    : [];

  setChartData({
    labels: labels,
    datasets: chartDatasets,
  });
  //console.log('chartData:',chartData);
  }, [fullData, selectedTimeRange, selectedChannelNicknames, selectedAuthorIds]); // Include selectedAuthorIds in the dependency array


return (
  <div style={{ width: '100%', height: 'auto', maxWidth: '1200px', margin: 'auto' }}>
    
    <div style={{ position: 'relative' }}> {/* Make sure this div is positioned relatively */}
      {isLoading && <div className="spinner"></div>}
      <input
        type="text"
        value={userToLookup}
        onChange={handleInputChange}
        placeholder="username"
      />
      {/* Autocomplete suggestions */}
      {filteredUsernames.length > 0 && (
  <ul style={{
    listStyleType: 'none',
    padding: 0,
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'white',
    width: '100%',
    boxShadow: '0px 4px 5px rgba(0,0,0,0.2)'
  }}>
    {filteredUsernames.map((username, index) => (
      <li key={index} onClick={() => handleUsernameClick(username)} style={{ cursor: 'pointer', padding: '5px' }}>
        {username}
      </li>
          ))}
        </ul>
      )}
      <button onClick={handleGoClick} style={{ marginTop: '2px' }}>Go</button> {/* Add margin-top if necessary */}
      {Object.keys(timeRanges).map((range) => (
        <button
          key={range}
          onClick={() => setSelectedTimeRange(range)}
          style={{ backgroundColor: selectedTimeRange === range ? 'green' : 'black' }}
        >
          {range}
        </button>
      ))}

  <div>
    <br /><br />
        {allDistinctAuthorIds.length > 0 && (
<div>
  {allDistinctAuthorIds.length > 0 && (
    <div>
      <p>distinct author_id values within results:</p>
      <ul>
        {allDistinctAuthorIds.map((authorId, index) => (
          <li key={index}>
            <label>
              <input
                type="checkbox"
                checked={selectedAuthorIds.has(authorId)}
                onChange={() => handleAuthorIdCheckboxChange(authorId)}
              />
              <a href={generateAuthorIdLink(authorId)} target="_blank" rel="noopener noreferrer">
                {authorId}
              </a>
            </label>
          </li>
        ))}
      </ul>
    </div>
  )}
</div>
        )}
  </div>
        <div>
          {channelNicknames.map(nickname => (
            <label key={nickname}>
              <input
                type="checkbox"
                checked={selectedChannelNicknames.has(nickname)}
                onChange={() => handleChannelCheckboxChange(nickname)}
              />
              {nickname}
            </label>
          ))}
        </div>


      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ width: '50%', paddingLeft: '4%', paddingRight: '1%', paddingTop: '2%' }}>
          <Line data={chartData} options={chartOptions} />
        </div>

  <div style={{ width: '50%', paddingLeft: '4%', paddingRight: '1%', paddingTop: '2%' }}>
<table style={tableStyle}>
  <thead>
    <tr>
      <th style={thStyle}>month</th>
      <th style={thStyle}>chat_count</th>
      <th style={thStyle}>sum_superchat_revenue</th>
    </tr>
  </thead>
  <tbody>
    {tableRows.map((row, index) => (
      <tr key={index}>
        <td style={tdStyle}>{row.month}</td>
        <td style={tdStyle}>{row.totalChats}</td>
        <td style={tdStyle}>{row.sumSuperchats}</td>
      </tr>
    ))}
    <tr>
      <td style={tdStyle}>summary</td>
      <td style={tdStyle}>{totals.totalChats} avg.</td>
      <td style={{...tdStyle, color: 'green'}}>${totals.sumSuperchats} total</td>
    </tr>
  </tbody>
</table>

  </div>
  
      </div>
  {renderDonorSummaryIframe()}
    </div>


    </div>
  );
};

export default UserLookup;


