import React, { useState, useEffect, useContext, useCallback } from 'react';
import Modal from "react-modal";
import { useTable } from 'react-table';
import { AuthContext, AuthProvider } from '../../AuthContext'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faScrollOld, faArrowsSpin, faComment, faTrash,
  faChartLine, faDownload, faHardDrive, faCircle,
  faCircleQuarterStroke, faCircleHalfStroke,
  faCircleThreeQuartersStroke, faBulldozer, faMessagesDollar
} from '@fortawesome/pro-solid-svg-icons';
import '../../animation.css';
import '../../animation_frozen.css';
import '../../gantt.css';
import '../../tables.css';
import ChatModal from '../../ChatModal';
import 'chart.js/auto';
import ViewerChartModal from '../../ViewerChartModal';
import handleTranscriptGeneration from '../../functions/transcript.js'
import ReactGA from "react-ga4";
import ChannelDropdown from './componentChannelDropdown';

ReactGA.send({ hitType: "pageview", page: "/UpcomingStreams", title: "streams" });

const mergeAndFilterStreams = (upcomingStreams, completedStreams, currentTime) => {
  const oneHourInMs = 60 * 60 * 1000;
  const twentyFourHoursInMs = 24 * oneHourInMs;
  const twentyFourHoursAgo = new Date(currentTime.getTime() - twentyFourHoursInMs);

  const transformedCompletedStreams = completedStreams.map(stream => ({
    ...stream,
    stream_scheduled_pst: stream.start_time,
    video_title: null,
    is_active: new Date(stream.end_time) > currentTime
  }));

  const filteredCompletedStreams = transformedCompletedStreams.filter(stream => {
    const endTime = new Date(stream.end_time);
    return endTime >= twentyFourHoursAgo && endTime <= currentTime;
  });

  const combinedStreams = [...upcomingStreams, ...filteredCompletedStreams];
  combinedStreams.sort((a, b) => new Date(a.stream_scheduled_pst) - new Date(b.stream_scheduled_pst));
  return combinedStreams;
};

const DataTable = ({ data, columns, expandedRowId, onRowClick }) => {
  const [isThumbnailModalOpen, setIsThumbnailModalOpen] = useState(false);
  const [thumbnailUrlHQ, setThumbnailUrlHQ] = useState('');

  const handleThumbnailClick = (url, urlHQ) => {
    setThumbnailUrlHQ(urlHQ);
    setIsThumbnailModalOpen(true);
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    borderColor: '1px solid #222f4a'
  };

  const thStyle = {
    backgroundColor: '#213454',
    fontWeight: 'bold',
    textAlign: 'left',
    padding: '8px',
    borderBottom: '1px solid #222f4a',
  };

  const tdStyle = {
    padding: '8px',
    borderBottom: '1px solid #2c3038',
    backgroundColor: (row) => (row.original.is_active ? '#8aff80' : 'inherit'),
    borderLeft: '1px solid #222f4a',
    borderRight: '1px solid #222f4a',
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <div className="my-table-container" style={{ maxHeight: "600px", overflowY: "auto" }}>
      <table {...getTableProps()} style={tableStyle}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} style={thStyle}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  let cellStyle = { ...tdStyle, ...cell.column.style };

                  if (cell.column.id === 'superchat_usd' ||
                    (cell.column.id === 'revenue' && row.original.is_active === true)) {
                    cellStyle = {
                      ...cellStyle,
                      backgroundColor: cell.value <= 10
                        ? 'red'
                        : cell.value <= 50
                          ? 'yellow'
                          : 'green'
                    };
                  } else if (cell.column.id === 'revenue' && row.original.is_active === false) {
                  } else {
                  }

                  if (cell.column.id === 'video_thumbnail') {
                    return (
                      <td {...cell.getCellProps()} style={cellStyle}>
                        <div onClick={() => handleThumbnailClick(cell.value, row.original.video_thumbnail_hq)}>
                          <img src={cell.value} alt="Thumbnail" style={{ width: '100px', cursor: 'pointer' }} />
                        </div>
                      </td>
                    );
                  } else {
                    return (
                      <td {...cell.getCellProps()} style={cellStyle}>
                        {cell.render('Cell')}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal
        isOpen={isThumbnailModalOpen}
        onRequestClose={() => setIsThumbnailModalOpen(false)}
        style={{
          content: {
            position: 'absolute',
            top: '55%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            background: 'opaque',
            padding: 0,
            border: 'none',
            overflow: 'visible'
          },
          overlay: {
            backgroundColor: 'black'
          }
        }}
        overlayClassName={"ReactModal__Overlay"}
        contentLabel="Expanded Thumbnail"
      >
        <div onClick={() => setIsThumbnailModalOpen(false)} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={thumbnailUrlHQ} alt="Expanded Thumbnail" style={{ width: '65vw', height: 'auto', maxWidth: '95vw', maxHeight: '95vh' }} />
        </div>
      </Modal>
    </div>
  );
};


const RouteStreams = () => {
  const { user } = useContext(AuthContext);
  const [viewerData, setViewerData] = useState([]);
  const [isChartModalOpen, setIsChartModalOpen] = useState(false);
  const [activeAndScheduledVideoTableData, setActiveAndScheduledVideoTableData] = useState([]);
  const [historyTableData, setHistoryTableData] = useState([]);
  const [expandedRowId, setExpandedRowId] = useState(null);
  const [showAllChats, setShowAllChats] = useState(false);
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [chatFilter, setChatFilter] = useState('');
  const [combinedStreams, setCombinedStreams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ArchivalModalOpen, setArchivalModalOpen] = useState(false);
  const defaultRequestNote = '';
  const [requestNote, setRequestNote] = useState(defaultRequestNote);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  const [selectedResult, setSelectedResult] = useState(null);
  const [showArchivalModal, setShowArchivalModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showRegenConfirmModal, setShowRegenConfirmModal] = useState(false);
  const [ShowChatBackloadConfirmModal, setShowChatBackloadConfirmModal] = useState(false);

  const [showMigrateConfirmModal, setShowMigrateConfirmModal] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [currentChannelNickname, setCurrentChannelNickname] = useState(null);
  
  const [channelNicknames, setChannelNicknames] = useState([]);
  const [selectedChannelNicknames, setSelectedChannelNicknames] = useState(new Set());
  const [lastSelectedChannels, setLastSelectedChannels] = useState(new Set());
  const [filteredActiveAndScheduledVideoTableData, setFilteredActiveAndScheduledVideoTableData] = useState([]);
  const [filteredHistoryTableData, setFilteredHistoryTableData] = useState([]);

  // New state for add-stream input
  const [newStreamId, setNewStreamId] = useState('');

  // Function to handle adding a new stream
  const handleAddStream = () => {
    if (user !== "mookie") {
      setShowPermissionModal(true);
      return;
    }
    fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/add_stream?videoid=${newStreamId}`)
      .then(response => response.json())
      .then(data => {
        console.log('Stream added:', data);
        // You might want to refresh the stream list or add the new stream to the existing list here
        setNewStreamId(''); // Clear the input after successful addition
      })
      .catch(error => console.error('Error adding stream:', error));
  };


  useEffect(() => {
    if (showArchivalModal) {
      setArchivalModalOpen(false);
    }
  }, [showArchivalModal]);

  const handleArchivalRequest = (result) => {
    setSelectedResult(result, () => {
      setRequestNote(defaultRequestNote);
      setResponseMessage('');
      setResponseStatus('');
      setArchivalModalOpen(true);
    });
  };

  useEffect(() => {
    // This code will run after setSelectedResult has updated the state
  }, [selectedResult]);

  const handleArchivalRequestGeneration = (result, requestNote) => {
    const video_id = result.original.video_id;
    const channel_nickname = result.original.channel_nickname;
    const request_note = requestNote;

    const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/request-archival?videoid=${video_id}&username=${user}&channel_nickname=${channel_nickname}&note=${request_note}`;
    setIsLoading(true);

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const { message, status } = data;
        setResponseMessage(message);
        setResponseStatus(status);
        if (status === 'success') {
          console.log('archival Request successful:', message);
        } else if (status === 'duplicate_video_id') {
          console.log('archival request rejected due to duplicate_video_id');
        } else {
          console.log('archival request failed due to Unknown status:', status);
        }
      })
      .catch((error) => {
        console.error('Error requesting archival:', error);
        setIsLoading(false);
      });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleRowClick = (row) => {
    if (modalIsOpen) {
      setIsModalOpen(false);
    } else {
      setExpandedRowId(row.original.video_id);
      setIsModalOpen(true);
    }
  };

  const handleMigrateConfirmYes = () => {
    const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/recover_video?videoid=${encodeURIComponent(currentVideoId)}&channel=${encodeURIComponent(currentChannelNickname)}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log('recover_video, response:', data);
      })
      .catch((error) => console.error('Error recovering the video:', error));
    setShowMigrateConfirmModal(false);
  };

  const handleConfirmYes = () => {
    const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/trash_video?videoid=${encodeURIComponent(currentVideoId)}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log('nuked, response:', data);
      })
      .catch((error) => console.error('Error trashing the video:', error));
    setShowConfirmModal(false);
  };

// handleBackloadChatYes
  const handleBackloadChatYes = () => {
    const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/backload_chat?videoid=${encodeURIComponent(currentVideoId)}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log('chat backload response, response:', data);
      })
      .catch((error) => console.error('Error backloading chat object:', error));
    setShowChatBackloadConfirmModal(false);
  };

  const handleRegenConfirmYes = () => {
    const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/regen_video_transcript?channel_nickname=${encodeURIComponent(currentChannelNickname)}&videoid=${encodeURIComponent(currentVideoId)}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log('nuked, response:', data);
      })
      .catch((error) => console.error('Error trashing the video:', error));
    setShowRegenConfirmModal(false);
  };

  const handleRowTrash = (video_id) => {
    setCurrentVideoId(video_id);
    if (user === 'localUser' || user === 'mookie') {
      setShowConfirmModal(true);
    } else {
      setShowPermissionModal(true);
    }
  };

  const handleBackloadChat = (video_id) => {
    setCurrentVideoId(video_id);
    if (user === 'localUser' || user === 'mookie') {
      setShowChatBackloadConfirmModal(true);
    } else {
      setShowPermissionModal(true);
    }
  };

  const handleRowRegenTranscript = (video_id, channel_nickname) => {
    setCurrentVideoId(video_id);
    setCurrentChannelNickname(channel_nickname);
    if (user === 'localUser' || user === 'mookie') {
      setShowRegenConfirmModal(true);
    } else {
      setShowPermissionModal(true);
    }
  };

  const handleRowMigrate = (video_id, channel_nickname) => {
    setCurrentVideoId(video_id);
    setCurrentChannelNickname(channel_nickname);
    if (user === 'localUser' || user === 'mookie') {
      setShowMigrateConfirmModal(true);
    } else {
      setShowPermissionModal(true);
    }
  };

  const handleViewersClick = (video_id) => {
    fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/streams?option=live_statistics&username=${user}&video_id=${video_id}`)
      .then((response) => response.json())
      .then((jsonData) => {
        setViewerData(jsonData.results);
        setIsChartModalOpen(true);
      })
      .catch((error) => console.error('Error fetching viewer data: ', error));
  };

  const ViewerChart = () => (
    <Modal
      isOpen={isChartModalOpen}
      onRequestClose={() => setIsChartModalOpen(false)}
      contentLabel="Viewer Chart"
    >
      <ViewerChartModal viewerData={viewerData} />
    </Modal>
  );

  const columnsActiveAndScheduledVideos = React.useMemo(
    () => [
      {
        id: 'channel_nickname',
        Header: 'channel',
        accessor: 'channel_nickname',
        width: 120,
        maxWidth: 120,
        Cell: ({ value, row }) => (
          <div style={{
            whiteSpace: 'wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '120px',
          }}>
            <a href={row.original.channel_streams_link} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          </div>
        ),
      },
      {
        id: 'video_id_link',
        Header: 'video_id',
        accessor: 'video_id',
        width: 120,
        maxWidth: 120,
        Cell: ({ value, row }) => (
          <div style={{
            whiteSpace: 'wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '120px',
          }}>
            <a href={`https://www.youtube.com/watch?v=${row.original.video_id}`} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          </div>
        ),
      },
      {
        Header: 'scheduled_pst',
        accessor: 'stream_scheduled_pst',
        width: 25
      },
      {
        Header: '',
        accessor: 'is_active',
        Cell: ({ value }) => (
          <div style={{ backgroundColor: value ? '#8aff80' : '#ffff8a' }}>
            {value ? 'LIVE' : 'Pending'}
          </div>
        ),
        style: {
          textAlign: 'center',
        },
      },
      {
        Header: '',
        accessor: 'video_thumbnail',
        Cell: ({ value, row }) => (
          <img src={value} alt="Thumbnail" style={{ width: '100px' }} />
        ),
      },
      {
        Header: 'title',
        accessor: 'video_title',
        width: 190,
        maxWidth: 190,
        Cell: ({ value, row }) => (
          <div style={{
            whiteSpace: 'wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '190px',
          }}>
            <a href={`https://www.youtube.com/watch?v=${row.original.video_id}`} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          </div>
        ),
      },
      {
        Header: 'mins',
        accessor: 'runtime',
      },
      {
        Header: () => (
          <div style={{ backgroundColor: '#314da8', color: 'black' }}>$$</div>
        ),
        accessor: 'revenue',
        Cell: ({ value }) => `$${value}`,
      },
      {
        Header: '$/hour',
        accessor: 'revenue_per_hour',
        Cell: ({ value }) => `$${value}`,
      },
      {
        Header: 'cpm',
        accessor: 'chats_per_min',
      },
      {
        Header: 'viewers',
        accessor: 'latest_concurrent_viewers',
      },
      {
        Header: 'actions',
        accessor: 'video_id',
        Cell: ({ row }) => (
          <div>
            <FontAwesomeIcon
              icon={faComment}
              className="fa-thin"
              style={{
                color: row.original.is_active ? 'green' : 'grey',
                display: 'inline-block',
                marginLeft: '10px',
                animation: row.original.is_active ? 'blinking 4.5s infinite' : 'none'
              }}
              onClick={() => { handleRowClick(row); }}
              title="live-chat"
            />
            <FontAwesomeIcon
              icon={faChartLine}
              className="fa-thin"
              style={{
                color: row.original.is_active ? 'green' : 'grey',
                animation: row.original.is_active ? 'blinking 4s infinite' : 'none',
                marginLeft: '10px'
              }}
              onClick={() => handleViewersClick(row.original.video_id)}
              title="live-statistics"
            />
            <FontAwesomeIcon
              icon={faTrash}
              className="fa-thin"
              style={{
                color: 'grey',
                display: 'inline-block',
                marginLeft: '10px'
              }}
              onClick={() => { handleRowTrash(row.original.video_id); }}
              title="delete"
            />
          </div>
        ),
      }
    ],
    []
  );

  const columnsVideoHistory = React.useMemo(
    () => [
      {
        id: 'video_type',
        Header: 'type',
        accessor: 'video_type',
        width: 20,
        maxWidth: 20,
        Cell: ({ value, row }) => (
          <div style={{
            whiteSpace: 'wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '90px',
          }}>
              {value}
          </div>
        ),
      }, 
      {
        id: 'channel',
        Header: 'channel',
        accessor: 'channel_nickname',
        width: 90,
        maxWidth: 90,
        Cell: ({ value, row }) => (
          <div style={{
            whiteSpace: 'wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '90px',
          }}>
            <a href={row.original.channel_streams_link} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          </div>
        ),
      },
      {
        Header: 'video_id',
        accessor: 'video_id',
        width: 120,
        maxWidth: 120,
        Cell: ({ value, row }) => (
          <div style={{
            whiteSpace: 'wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '120px',
          }}>
            <a href={`https://www.youtube.com/watch?v=${row.original.video_id}`} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          </div>
        ),
      },
      {
        Header: 'started_pst',
        accessor: 'start_time',
      },
      {
        Header: '',
        accessor: 'video_thumbnail',
        Cell: ({ value, row }) => (
          <img src={value} alt="Thumbnail" style={{ width: '100px' }} />
        ),
      },
      {
        Header: 'title',
        accessor: 'video_title',
        width: 190,
        maxWidth: 190,
        Cell: ({ value, row }) => (
          <div style={{
            whiteSpace: 'wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '190px',
          }}>
            <a href={`https://www.youtube.com/watch?v=${row.original.video_id}`} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          </div>
        ),
      },
      {
        Header: 'mins',
        accessor: 'mins_duration',
      },
      {
        Header: () => (
          <div style={{ backgroundColor: '#314da8', color: 'black' }}>$$</div>
        ),
        accessor: 'superchat_usd',
        Cell: ({ value }) => `$${value}`,
      },
      {
        Header: '$/hour',
        accessor: 'revenue_per_hour',
        Cell: ({ value }) => `$${value}`,
      },
      {
        Header: 'view',
        accessor: 'avg_concurrent_viewers',
      },
      {
        Header: 'cpm',
        accessor: 'avg_chat_rate',
      },
      {
        id: 'actions',
        Header: 'actions',
        accessor: 'video_id',
        Cell: ({ row }) => {
          let hardDriveColor = 'grey';
          let hardDriveAnimation = '';

          if (row.original.is_archiving) {
            hardDriveColor = 'blue';
            hardDriveAnimation = 'blinking 2s infinite';
          } else if (!row.original.is_archiving && row.original.is_archived) {
            hardDriveColor = 'blue';
          }

          const getScrollIconAndStyle = (row) => {
            if (row.original.is_transcribing) {
              let icon, color = 'yellow', animation = 'blinking 4s infinite';

              if (row.original.transcribe_percentage >= 0.75) {
                icon = faCircleThreeQuartersStroke;
              } else if (row.original.transcribe_percentage >= 0.5) {
                icon = faCircleHalfStroke;
              } else if (row.original.transcribe_percentage >= 0.25) {
                icon = faCircleQuarterStroke;
              } else {
                icon = faCircle;
              }

              return { icon, color, animation };

            } else if (!row.original.is_transcribing && row.original.is_transcribed) {
              return {
                icon: faScrollOld,
                color: 'blue',
                animation: ''
              };
            }

            return {
              icon: faScrollOld,
              color: 'grey',
              animation: ''
            };
          }

          const scrollIconAndStyle = getScrollIconAndStyle(row);

          const handleChartLineClick = (e) => {
            e.stopPropagation();
            handleViewersClick(row.original.video_id);
          };

          return (
            <div>
              <FontAwesomeIcon
                icon={faHardDrive}
                className="fa-regular fa-fw"
                style={{ marginRight: '1px', marginLeft: '1px', color: hardDriveColor, animation: hardDriveAnimation }}
                onClick={() => handleTranscriptGeneration(row.original.video_id)}
                title="archive-status"
              />
              <FontAwesomeIcon
                icon={scrollIconAndStyle.icon}
                className="fa-thin fa-fw"
                style={{ marginRight: '1px', marginLeft: '1px', color: scrollIconAndStyle.color, animation: scrollIconAndStyle.animation }}
                onClick={() => handleTranscriptGeneration(row.original.video_id)}
                title="request-transcript"
              />
              <FontAwesomeIcon
                icon={faComment}
                className="fa-thin fa-fw"
                style={{ color: 'blue', display: 'inline-block', marginRight: '1px', marginLeft: '1px' }}
                onClick={() => { handleRowClick(row); }}
                title="chat-history"
              />
              <br />
              <FontAwesomeIcon
                icon={faChartLine}
                className="fa-thin fa-fw"
                style={{ color: 'blue', marginLeft: '1px', marginRight: '1px' }}
                onClick={handleChartLineClick}
                title="statistics"
              />
              <FontAwesomeIcon
                icon={faDownload}
                className="fa-thin fa-fw"
                style={{ color: 'blue', marginLeft: '1px', marginRight: '1px' }}
                onClick={() => {
                  handleArchivalRequest(row);
                  setSelectedResult(row);
                  setShowArchivalModal(true);
                }}
                title="request-archival-download"
              />
              <FontAwesomeIcon
                icon={faTrash}
                className="fa-thin"
                style={{
                  color: 'blue',
                  display: 'inline-block',
                  marginLeft: '5px'
                }}
                onClick={() => { handleRowTrash(row.original.video_id); }}
                title="delete"
              />
              <br />
              <FontAwesomeIcon
                icon={faBulldozer}
                className="fa-thin"
                style={{
                  color: 'blue',
                  display: 'inline-block',
                  marginLeft: '1px',
                  marginRight: '1px'
                }}
                onClick={() => { handleRowMigrate(row.original.video_id, row.original.channel_nickname); }}
                title="recover_from_live_capture"
              />
              <FontAwesomeIcon
                icon={faArrowsSpin}
                className="fa-thin"
                style={{
                  color: 'blue',
                  display: 'inline-block',
                  marginLeft: '1px',
                  marginRight: '1px'
                }}
                onClick={() => { handleRowRegenTranscript(row.original.video_id, row.original.channel_nickname); }}
                title="regenerate_transcript"
              />
              <FontAwesomeIcon
                icon={faMessagesDollar}
                className="fa-light"
                style={{
                  color: 'blue',
                  display: 'inline-block',
                  marginLeft: '1px',
                  marginRight: '1px'
                }}
                onClick={() => { handleBackloadChat(row.original.video_id); }}
                title="try_chat_backload"
              />
            </div>
          )
        },
      }
    ],
    []
  );

  const fetchData = useCallback(async () => {
    const responseActiveAndScheduledVideos = await fetch(
      `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/streams?option=active_and_upcoming_streams&username=${user}`
    );
    const jsonActiveAndScheduledVideos = await responseActiveAndScheduledVideos.json();
    const parsedActiveAndScheduledVideos = jsonActiveAndScheduledVideos.results;
    setActiveAndScheduledVideoTableData(parsedActiveAndScheduledVideos);

    const responseVideoHistory = await fetch(
      `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/streams?option=video_history&username=${user}`
    );
    const jsonResponseVideoHistory = await responseVideoHistory.json();
    const parsedVideoHistory = jsonResponseVideoHistory.results;
    setHistoryTableData(parsedVideoHistory);

    const allChannelNicknames = new Set([...parsedActiveAndScheduledVideos, ...parsedVideoHistory].map(stream => stream.channel_nickname));
    setChannelNicknames([...allChannelNicknames]);

    // Update selectedChannelNicknames based on lastSelectedChannels
    if (lastSelectedChannels.size === 0) {
      // If it's the first load, select all channels
      setSelectedChannelNicknames(new Set([...allChannelNicknames]));
      setLastSelectedChannels(new Set([...allChannelNicknames]));
    } else {
      // Keep only the channels that still exist and were previously selected
      const updatedSelection = new Set([...lastSelectedChannels].filter(channel => allChannelNicknames.has(channel)));
      setSelectedChannelNicknames(updatedSelection);
    }
  }, [user, lastSelectedChannels]);

  useEffect(() => {
    fetchData();
    const timer = setInterval(fetchData, 10000);
    return () => clearInterval(timer);
  }, [fetchData]);

  useEffect(() => {
    const currentTime = new Date();
    const mergedStreams = mergeAndFilterStreams(activeAndScheduledVideoTableData, historyTableData, currentTime);
    
    const filteredActiveAndScheduled = activeAndScheduledVideoTableData.filter(stream => 
      selectedChannelNicknames.has(stream.channel_nickname)
    );
    const filteredHistory = historyTableData.filter(stream => 
      selectedChannelNicknames.has(stream.channel_nickname)
    );
    
    setFilteredActiveAndScheduledVideoTableData(filteredActiveAndScheduled);
    setFilteredHistoryTableData(filteredHistory);

    const filteredMergedStreams = mergedStreams.filter(stream => 
      selectedChannelNicknames.has(stream.channel_nickname)
    );
    setCombinedStreams(filteredMergedStreams);
  }, [activeAndScheduledVideoTableData, historyTableData, selectedChannelNicknames]);

  const handleChannelSelection = (newSelection) => {
    setSelectedChannelNicknames(newSelection);
    setLastSelectedChannels(newSelection);
  };


 return (
  <>
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '1400px' }}>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          marginBottom: '10px',
          padding: '0 30px'
        }}>
          <ChannelDropdown
            channelNicknames={channelNicknames}
            selectedChannelNicknames={selectedChannelNicknames}
            onChange={handleChannelSelection}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="text"
              value={newStreamId}
              onChange={(e) => setNewStreamId(e.target.value)}
              placeholder="add-stream"
              style={{ 
                marginRight: '10px',
                padding: '5px 10px',
                backgroundColor: '#424242',
                color: '#fff',
                border: 'none',
                borderRadius: '4px'
              }}
            />
            <button 
              onClick={handleAddStream}
              style={{
                padding: '5px 15px',
                backgroundColor: '#606060',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
            >
              Go
            </button>
          </div>
        </div>

      <DataTable
        data={filteredActiveAndScheduledVideoTableData}
        columns={columnsActiveAndScheduledVideos}
        expandedRowId={expandedRowId}
        onRowClick={handleRowClick}
      />
          <ChatModal
            key={expandedRowId}
            videoId={expandedRowId}
            closeExpandedRow={() => setIsModalOpen(false)}
            showAllChats={showAllChats}
            setShowAllChats={setShowAllChats}
            chatFilter={chatFilter}
            setChatFilter={setChatFilter}
            closeModal={closeModal}
            isModalOpen={modalIsOpen}
            setIsModalOpen={setIsModalOpen}
          />

          {isChartModalOpen && (
            <ViewerChartModal
              viewerData={viewerData}
              isChartModalOpen={isChartModalOpen}
              closeChartModal={() => setIsChartModalOpen(false)}
            />
          )}

      <DataTable
        data={filteredHistoryTableData}
        columns={columnsVideoHistory}
      />

          <Modal className='clip-modal-content'
            isOpen={showMigrateConfirmModal}
            onRequestClose={() => setShowMigrateConfirmModal(false)}
          >
            <div>Are you sure you want to archive the livestream from a capture file?</div>
            <div>
              <button onClick={handleMigrateConfirmYes}>Yes</button>
              <button onClick={() => setShowMigrateConfirmModal(false)}>No</button>
            </div>
          </Modal>

          <Modal className='clip-modal-content'
            isOpen={showRegenConfirmModal}
            onRequestClose={() => setShowRegenConfirmModal(false)}
          >
            <div>Are you sure you want to regen the transcript from a capture file?</div>
            <div>
              <button onClick={handleRegenConfirmYes}>Yes</button>
              <button onClick={() => setShowRegenConfirmModal(false)}>No</button>
            </div>
          </Modal>

          <Modal className='clip-modal-content'
            isOpen={ShowChatBackloadConfirmModal}
            onRequestClose={() => setShowChatBackloadConfirmModal(false)}
          >
            <div>Are you sure you want to try backloading chat object?</div>
            <div>
              <button onClick={handleBackloadChatYes}>Yes</button>
              <button onClick={() => setShowChatBackloadConfirmModal(false)}>No</button>
            </div>
          </Modal>

          <Modal className='clip-modal-content'
            isOpen={showConfirmModal}
            onRequestClose={() => setShowConfirmModal(false)}
          >
            <div>Are you sure?</div>
            <div>
              <button onClick={handleConfirmYes}>Yes</button>
              <button onClick={() => setShowConfirmModal(false)}>No</button>
            </div>
          </Modal>

          <Modal className='clip-modal-content'
            isOpen={showPermissionModal}
            onRequestClose={() => setShowPermissionModal(false)}
          >
            <div>You do not have permission</div>
            <button onClick={() => setShowPermissionModal(false)}>OK</button>
          </Modal>

          {showArchivalModal && selectedResult && (
            <Modal
              className='clip-modal-content'
              isOpen={showArchivalModal}
              onRequestClose={() => {
                setRequestNote(defaultRequestNote);
                setResponseMessage('');
                setResponseStatus('');
                setShowArchivalModal(false);
                setIsLoading(false);
              }}
            >
              <h6>ARCHIVAL REQUEST {selectedResult?.original.video_title}</h6>
              <label>
                Add a request note (optional)
                <br />
                <input type="text" value={requestNote} onChange={(e) => setRequestNote(e.target.value)} />
              </label>
              <button onClick={() => handleArchivalRequestGeneration(selectedResult, requestNote)}>Submit</button>
              <div>
                <p>{responseMessage} {responseStatus}</p>
                <button onClick={() => {
                  setShowArchivalModal(false);
                  setResponseStatus('');
                  setResponseMessage('');
                  setIsLoading(false);
                }}>Close</button>
              </div>
            </Modal>
          )}
        </div>

        {isChartModalOpen && <ViewerChartModal viewerData={viewerData} closeModal={() => setIsChartModalOpen(false)} />}
      </div>
    </>
  );
};

export default RouteStreams;
