import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import ReactModal from 'react-modal';
import { AuthContext } from '../../AuthContext';
import '../../animation.css';
import './mentions.css';
import '../../tables.css';
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lottie/Basketball.json';
import { ClippingModal } from '../../components/Clipping';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faOctagonMinus } from '@fortawesome/pro-solid-svg-icons';
import { faUpload } from '@fortawesome/pro-solid-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { faChevronsRight } from '@fortawesome/pro-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { faChevronsLeft } from '@fortawesome/pro-solid-svg-icons';
import { faArrowLeftToLine } from '@fortawesome/pro-solid-svg-icons';
import { faArrowRightToLine } from '@fortawesome/pro-solid-svg-icons';
import { faScissors } from '@fortawesome/pro-duotone-svg-icons';
import { faScrollOld } from '@fortawesome/pro-solid-svg-icons';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';

import {
  enable as enableDarkMode,
  disable as disableDarkMode,
  auto as followSystemColorScheme,
  exportGeneratedCSS as collectCSS,
  isEnabled as isDarkReaderEnabled
} from 'darkreader';


enableDarkMode({
  brightness: 100,
  contrast: 90,
  sepia: 10,
});

// Set app element for accessibility
ReactModal.setAppElement('#root');

const Mentions = () => {
  const lottie = useRef(null);

  const { user } = useContext(AuthContext);
  const [tableData, setTableData] = useState([]);
  const [patterns, setPatterns] = useState([]);
  const [selectedPatterns, setSelectedPatterns] = useState({});
  const [channels, setChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const [filterText, setFilterText] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const [clipModalOpen, setClipModalOpen] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);

  // Function to fetch data
  const fetchData = () => {
    setLoading(true);
    if (user === 'mookie') {
      fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/mentions?username=${user}&option=get_recent`)
        .then((response) => response.json())
        .then((jsonData) => {
          setTableData(jsonData.results);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching data: ', error);
          setLoading(false); // Make sure to set loading to false even if there's an error
        });
    } else {
      setLoading(false); // Set loading to false if the user is not 'mookie'
    }
  };

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, [user]);

  // Update patterns, channels, selectedPatterns, and selectedChannels when tableData changes
  useEffect(() => {
    // Extract unique patterns
    const uniquePatterns = [...new Set(tableData.map(item => item.pattern))];
    setPatterns(uniquePatterns);

    // Initialize selectedPatterns with all patterns set to true
    const initialSelectedPatterns = {};
    uniquePatterns.forEach(pattern => {
      initialSelectedPatterns[pattern] = true;
    });
    setSelectedPatterns(initialSelectedPatterns);

    // Extract unique channels
    const uniqueChannels = [...new Set(tableData.map(item => item.channel))];
    setChannels(uniqueChannels);

    // Initialize selectedChannels with all channels set to true
    const initialSelectedChannels = {};
    uniqueChannels.forEach(channel => {
      initialSelectedChannels[channel] = true;
    });
    setSelectedChannels(initialSelectedChannels);

  }, [tableData]);

  const handlePatternChange = (pattern) => {
    setSelectedPatterns(prevSelectedPatterns => ({
      ...prevSelectedPatterns,
      [pattern]: !prevSelectedPatterns[pattern],
    }));
  };

  const handleChannelChange = (channel) => {
    setSelectedChannels(prevSelectedChannels => ({
      ...prevSelectedChannels,
      [channel]: !prevSelectedChannels[channel],
    }));
  };

  // Function to toggle all patterns between select all and deselect all
  const toggleAllPatterns = () => {
    const allSelected = Object.values(selectedPatterns).every(value => value);
    const newSelectedPatterns = {};
    patterns.forEach(pattern => {
      newSelectedPatterns[pattern] = !allSelected;
    });
    setSelectedPatterns(newSelectedPatterns);
  };

  // Function to toggle all channels between select all and deselect all
  const toggleAllChannels = () => {
    const allSelected = Object.values(selectedChannels).every(value => value);
    const newSelectedChannels = {};
    channels.forEach(channel => {
      newSelectedChannels[channel] = !allSelected;
    });
    setSelectedChannels(newSelectedChannels);
  };

  const handleResetData = () => {
    // Reset data by fetching it again
    fetchData();
    // Reset other states if needed
    setFilterText('');
    setSortConfig({ key: null, direction: null });
    setCurrentPage(1);
  };

  const handleClipButtonClick = (event, result) => {
    event.stopPropagation();
    setSelectedResult(result);
    setClipModalOpen(true);
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    } else {
      direction = 'ascending';
    }
    setSortConfig({ key, direction });
  };

  const getFilteredAndSortedData = () => {
    let filteredItems = [...tableData];

    if (filterText) {
      filteredItems = filteredItems.filter(item =>
        item.context.toLowerCase().includes(filterText.toLowerCase()) ||
        item.channel.toLowerCase().includes(filterText.toLowerCase()) ||
        item.video_title.toLowerCase().includes(filterText.toLowerCase())
      );
    }

    filteredItems = filteredItems.filter(item => selectedPatterns[item.pattern]);

    filteredItems = filteredItems.filter(item => selectedChannels[item.channel]);

    if (sortConfig !== null && sortConfig.key) {
      filteredItems.sort((a, b) => {
        if (typeof a[sortConfig.key] === 'string') {
          return sortConfig.direction === 'ascending'
            ? a[sortConfig.key].localeCompare(b[sortConfig.key], 'en', { sensitivity: 'base' })
            : b[sortConfig.key].localeCompare(a[sortConfig.key], 'en', { sensitivity: 'base' });
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
        }
        return 0;
      });
    }

    return filteredItems;
  };

  const sortedData = getFilteredAndSortedData();

  const patternCounts = useMemo(() => {
    const counts = {};
    tableData.forEach(item => {
      counts[item.pattern] = (counts[item.pattern] || 0) + 1;
    });
    return counts;
  }, [tableData]);

  const channelCounts = useMemo(() => {
    const counts = {};
    // Filter tableData by selectedPatterns
    const filteredByPatterns = tableData.filter(item => selectedPatterns[item.pattern]);
    filteredByPatterns.forEach(item => {
      counts[item.channel] = (counts[item.channel] || 0) + 1;
    });
    return counts;
  }, [tableData, selectedPatterns]);

  // Pagination logic remains the same
  const totalPagesFiltered = Math.ceil(sortedData.length / itemsPerPage);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentResults = sortedData.slice(firstIndex, lastIndex);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPagesFiltered) setCurrentPage(currentPage + 1);
  };

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const openModalWithVideo = (channel) => {
    setVideoUrl(channel.s3_signed_url);
    setSelectedResult(channel);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setVideoUrl('');
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
  };

  // If user is not 'mookie', render an unauthorized message
  if (user !== 'mookie') {
    return <div>You're not authorized to view this content.</div>;
  }

  // Display the loading animation when data is being fetched
  if (loading) {
    return (
      <div className="loading-container" style={{ textAlign: 'center' }}>
        <p>Loading...</p>
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    );
  }

  // Function to highlight pattern in context
  const highlightPattern = (context, pattern) => {
    if (!pattern) return context;
    const regex = new RegExp(`(${pattern})`, 'gi');
    const parts = context.split(regex);
    return (
      <>
        {parts.map((part, i) =>
          part.toLowerCase() === pattern.toLowerCase() ? (
            <span key={i} style={{ color: 'red', fontWeight: 'bold' }}>{part}</span>
          ) : (
            part
          )
        )}
      </>
    );
  };

  const columnWidths = {
    timestamp: '100px',
    channel: '50px',
    pattern: '80px',
    type: '40px',
    videoTitle: '250px',
    context: '300px',
    attribution: '100px'
  };

  return (
    <div className="container">
      <div style={{ width: '100%' }}>
        <br />
        <div className="data">
          <div className="controls-container">
            <button className="reset-button" onClick={handleResetData}>refresh</button>
            <input
              type="text"
              value={filterText}
              onChange={handleFilterChange}
              className="filter-input-mentions"
              placeholder="Filter by Context"
            />
          </div>
          <br />

          {/* Result Selections Container */}
          <div className="result-selections-container">
            <button
              className="clear-button"
              onClick={toggleAllPatterns}
              title="Toggle select/deselect all patterns"
              style={{ marginRight: '10px' }}
            >
              <FontAwesomeIcon icon={faOctagonMinus}
                style={{ color: 'lightblue', background: 'none' }} />
            </button>
            <span className="patterns-label">Patterns: &nbsp; </span>
            {patterns.map(pattern => (
              <label key={pattern} style={{ marginRight: '10px' }}>
                <input
                  type="checkbox"
                  checked={selectedPatterns[pattern]}
                  onChange={() => handlePatternChange(pattern)}
                />
                {pattern} [{patternCounts[pattern] || 0}]
              </label>
            ))}
          </div>

          {/* Channels Selections Container */}
          <div className="channel-selections-container">
            <button
              className="clear-button"
              onClick={toggleAllChannels}
              title="Toggle select/deselect all channels"
              style={{ marginRight: '10px' }}
            >
              <FontAwesomeIcon icon={faOctagonMinus}
                style={{ color: 'lightblue', background: 'transparent' }} />
            </button>
            <span className="channels-label">Channels:</span>
            {channels.map(channel => (
              <label key={channel} style={{ marginRight: '10px' }}>
                <input
                  type="checkbox"
                  checked={selectedChannels[channel]}
                  onChange={() => handleChannelChange(channel)}
                />
                {channel} [{channelCounts[channel] || 0}]
              </label>
            ))}
          </div>
        </div>

        <div style={{ width: '1400px' }}>
          <table className="data" style={{ width: '85%', tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th style={{ width: columnWidths.type }} onClick={() => requestSort('mention_type')}>Type</th>
                <th style={{ width: columnWidths.timestamp }} onClick={() => requestSort('sent_ts_pst')}>Timestamp</th>
                <th style={{ width: columnWidths.channel }} onClick={() => requestSort('channel')}>Channel</th>
                <th style={{ width: columnWidths.pattern }} onClick={() => requestSort('pattern')}>Pattern</th>
                <th style={{ width: columnWidths.attribution }} onClick={() => requestSort('attribution_name')}>Attribution</th>
                <th style={{ width: columnWidths.context }} onClick={() => requestSort('context')}>Context</th>
              </tr>
            </thead>

            <tbody>
              {currentResults.map((channel, index) => (
                <tr
                  key={index}
                  className={`tableRow ${channel.mention_type === 'voice' ? 'voice-type' : ''}`}
                  onClick={() => channel.mention_type === 'voice' && openModalWithVideo(channel)}
                  style={{
                    cursor: channel.mention_type === 'voice' ? 'pointer' : 'default',
                  }}
                >
                  <td>{channel.mention_type}</td>
                  <td
                    style={{
                      width: columnWidths.timestamp,
                      maxWidth: columnWidths.timestamp,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {channel.sent_ts_pst}
                  </td>
                  <td
                    style={{
                      width: columnWidths.channel,
                      maxWidth: columnWidths.channel,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {channel.channel}
                  </td>
                  <td>{channel.pattern}</td>
                  <td
                    style={{
                      width: columnWidths.attribution,
                      maxWidth: columnWidths.attribution,
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {channel.attribution_name}
                  </td>
                  <td
                    style={{
                      width: columnWidths.context,
                      maxWidth: columnWidths.context,
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {highlightPattern(channel.context, channel.pattern)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination Controls */}
          <div className="pagination-controls">
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              Previous
            </button>
            <span>Page {currentPage} of {totalPagesFiltered}</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPagesFiltered}>
              Next
            </button>
          </div>
        </div>
      </div>

      {/* Modal for playing video */}
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '1000px',
            height: '600px',
          },
        }}
      >
        <div>
          <video controls autoPlay style={{ width: '100%', height: '100%' }}>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
            <button onClick={closeModal}>Close</button>
            <button onClick={(event) => handleClipButtonClick(event, selectedResult)}>Clip</button>
          </div>
        </div>
      </ReactModal>

      {/* Include the ClippingModal */}
      {selectedResult && (
        <ClippingModal
          selectedResult={selectedResult}
          clipModalOpen={clipModalOpen}
          setClipModalOpen={setClipModalOpen}
          handleYoutubeUploadClick={() => console.log('YouTube Upload Clicked')}
        />
      )}
    </div>
  );
};

export default Mentions;
