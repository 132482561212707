import React, { useState, useEffect, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import './tables.css';
import './video_suggestions.css'
import { AuthContext } from './App';

Chart.register(...registerables);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

const truncateDescription = (description, maxLength) => {
  if (description.length > maxLength) {
    return description.substring(0, maxLength) + '...';
  }
  return description;
};

const SearchVideos = () => {
  const [searchInput, setSearchInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (searchInput.trim() === '') {
      setSuggestions([]);
      return;
    }

    const fetchData = async () => {
  setLoading(true);
  try {
    const response = await fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/search-videos-es?username=mookie&search=${encodeURIComponent(searchInput)}`);
    const data = await response.json();
    if (data.results) {
      const storedChannelData = JSON.parse(localStorage.getItem('channel-data') || '{"results":[]}');
      const channelDataArray = storedChannelData.results;
      const mergedData = data.results.map(item => {
        const channelInfo = channelDataArray.find(channel => channel[0] === item.channel_lookup_id) || [];
        return { ...item, channel_nickname: channelInfo[2] || 'Unknown Channel' };
      });

      setSuggestions(mergedData);
    } else {
      setSuggestions([]);
    }
  } catch (error) {
    console.error('Error fetching suggestions:', error);
    setSuggestions([]);
  } finally {
    setLoading(false);
  }
};

    const timeoutId = setTimeout(fetchData, 300);
    return () => clearTimeout(timeoutId);
  }, [searchInput]);


// <a 
//     href={`https://www.youtube.com/watch?v=${suggestion.video_id}`}
//     target="_blank" // Opens in a new tab
//     rel="noopener noreferrer" // Security for opening links in a new tab
//   >


return (
  <div>
    <input
      type="text"
      value={searchInput}
      onChange={(e) => setSearchInput(e.target.value)}
      placeholder="Search videos..."
    />
    {loading && <div></div>}
    <div className="my-container">
    <ul>
      {suggestions.map((suggestion, index) => (
        <li key={index} className="suggestion-item">
                    <img 
  src={suggestion.video_thumbnail || `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/get-thumbnail?vid=${suggestion.video_id}`} 
  alt={`${suggestion.video_title} thumbnail`} 
  className="video-thumbnail" 
  onError={(e) => {
    const fallbackUrl = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/get-thumbnail?vid=${suggestion.video_id}`;
    if (e.target.src !== fallbackUrl) {
      e.target.onerror = null; // Prevents further triggering of onError
      e.target.src = fallbackUrl;
    }
  }}
/>
          <div className="video-details">
            <div className="video-date">{formatDate(suggestion.video_published_ts_utc)}</div>
            <div className="channel-nickname">{suggestion.channel_nickname || 'Unknown Channel'}</div>
            <div className="channel-nickname"> <a 
        href={`https://www.youtube.com/watch?v=${suggestion.video_id}`}
        target="_blank"
        rel="noopener noreferrer"
      >{suggestion.video_id}
      </a></div>
            <div className="video-title">{suggestion.video_title}</div>
            <div className="video-description">{truncateDescription(suggestion.video_description, 125)}</div> {/* Adjust 50 to however many characters you prefer */}
            
          </div>
        </li>
      ))}
    </ul>
    </div>
  </div>
);
};

export default SearchVideos;